import React, { useEffect, useMemo, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    ModalHeader
} from "reactstrap";

import toastr from "toastr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { del, get, post, put, putForm } from "helpers/api_helper";
import moment from 'moment';

import './project.css';
import Select from "react-select";
import DeleteModal from "components/DeleteModal";

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const ProjectDetail = () => {
    document.title = "Project";
    const navigate = useNavigate();

    const extractBaseUrl = (url) => {
        const parsedUrl = new URL(url);
        return `${parsedUrl.protocol}//${parsedUrl.hostname}/`;
      };
    const imageUrl = extractBaseUrl(process.env.REACT_APP_API_URL);

    const [userRole, setUserRole] = useState('');
    const [project, setProject] = useState({});
    const { id } = useParams();

    const [Name, setName] = useState('');
    const [Type, setType] = useState('');
    const [Description, setDescription] = useState('');
    const [Amount, setAmount] = useState();
    const [HeadCost, setHeadCost] = useState('');
    const [ApprovalLogs, setApprovalLogs] = useState([]);

    const [media, setMedia] = useState([]);

    const [eo, setEo] = useState([]);
    const [hod, setHod] = useState([]);
    const [ps, setPs] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [vendor, setVendor] = useState([]);

    const [EoID, setEoID] = useState('');
    const [hodID, setHodID] = useState('');
    const [PsID, setPsID] = useState('');
    const [employeeId, setEmployeeId] = useState();
    const [vendorId, setVendorId] = useState();

    const [employeeList, setEmployeeList] = useState([]);
    const [vendorList, setVendorList] = useState([]);

    const [deletedFiles, setDeletedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    
    const [rejectModal, setRejectModal] = useState(false);
    const [Comment, setComment] = useState("");

    const [HeadCostIDs, setHeadCostIDs] = useState([]);

    const [milestoneModal, setMilestoneModal] = useState(false);

    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedProjectVendor, setSelectedProjectVendor] = useState(null);

    const [selectedHeadCost, setSelectedHeadCost] = useState();
    const [selectedAmount, setSelectedAmount] = useState();
    const [selectedName, setSelectedName] = useState();
    const [vendorMilestones, setVendorMilestones] = useState([]);

    const [milestoneDescription, setMilestoneDescription] = useState("");
    const [expectedDate, setExpectedDate] = useState();
    const [completedDate, setCompletedDate] = useState();

    const [ActivityLogs, setActivityLogs] = useState([]);

    const [deleteEmployeeModal, setDeleteEmployeeModal] = useState(false);
    const [deleteVendorModal, setDeleteVendorModal] = useState(false);
    const [deleteMilestoneModal, setDeleteMilestoneModal] = useState(false);
    const [tempDelete, setTempDelete] = useState();

    const handleResetModal = () => {
        setDeleteEmployeeModal(false);
        setDeleteMilestoneModal(false);
        setDeleteVendorModal(false);
        setTempDelete(null);
    }
    
    const handleDeleteMilestoneModal = (data) => {
        setTempDelete(data);
        setDeleteMilestoneModal(true);
    }
    
    const handleDeleteVendorModal = (data) => {
        setTempDelete(data);
        setDeleteVendorModal(true);
    }

    const handleDeleteEmployeeModal = (data) => {
        setTempDelete(data);
        setDeleteEmployeeModal(true);
    }

    const disabledField = userRole !== 'Preparation Clerk';

    const openMilestone = (data) => {
        setSelectedVendor(data?.vendor?.UserID);
        setSelectedProjectVendor(data?.ID);
        setVendorMilestones(project?.milestoneProject?.filter(x=>x.ProjectVendorID == data?.ID));
        setMilestoneModal(true);
    }

    const closeMilestoneModal = () => {
        setSelectedVendor(null);
        setSelectedProjectVendor(null);
        setMilestoneModal(false);
        setSelectedHeadCost(null);
        setSelectedAmount(null);
        setSelectedName(null);
    }

    const addMilestone = async () => {
        const data = {
            ProjectVendorID: selectedProjectVendor,
            ProjectID: id,
            VendorID: selectedVendor,
            HeadCostTypeID: selectedHeadCost,
            Name: selectedName,
            Amount: selectedAmount,
            Description: milestoneDescription,
            DueDate: expectedDate,
            CompletedDate: completedDate,
        }

        await post('project/create-vendor-milestone', data);
        closeMilestoneModal();
        getProjectById();
    }

    const deleteMilestone = async (id) => {
        await del(`project/milestone/delete/${id}`);
        getProjectById();
        handleResetModal();
    }

    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    const getProjectById = async () => {
        const res = await get(`project/find/${id}`);
        setProject(res);
        setName(res.Name);
        setType(res.Type);
        setDescription(res.Description);
        setAmount(res.Amount);
        setHeadCost(res.HeadCost);
        setEoID(res.EoID);
        setHodID(res.hodID);
        setPsID(res.PsID);
        setEmployeeList(res.employees);
        setVendorList(res.vendors);
        setApprovalLogs(res.ApprovalLogs);
        setMedia(res.media);
        setHeadCostIDs(res.headCostTypes);
        setActivityLogs(res.projectHistory || []);
    }

    useEffect(()=>{
        if(selectedProjectVendor){
            setVendorMilestones(project?.milestoneProject?.filter(x=>x?.ProjectVendorID == selectedProjectVendor));
        }
    },[project]);

    const getUsers = async () => {
        const res = await get('user/find');
        setEo(res.filter(x => x.role.Name == 'Examination Officer'));
        // setHod(res.filter(x=> x.role.Name == 'Head of Department'));
        setHod(res.filter(x => x.role.Name == 'HOD'));
        setPs(res.filter(x => x.role.Name == 'Permanent Secretary'));
        setEmployee(res.filter(x => x.role.Name == 'Employee'));
        setVendor(res.filter(x => x.role.Name == 'Vendor'));
    }

    const addEmployee = async () => {

        if (!employeeId) {
            toastr.error("Please select an employee before adding.");
            return;
        }

        const isEmployeeAlreadyAdded = employeeList.some(e => e.UserID == employeeId);

        if (isEmployeeAlreadyAdded) {
            toastr.error("This employee is already added to the list.");
            // alert(); // Validation message
            setEmployeeId("");
            return;
        }

        try {
            await post('project/add-employee', { ProjectID: id, UserID: employeeId });
            getProjectById();
            setEmployeeId("");
            toastr.success("Employee added successfully!"); // Feedback to user
        } catch (error) {
            console.error("Error adding vendor:", error);
            toastr.error("Failed to add employee. Please try again."); // Error message
        } finally {
            // setIsLoading(false); // Stop loader
        }
    }

    const deleteEmployee = async (employeeId) => {
        try {
            await post('project/delete-employee', { ProjectID: id, UserID: employeeId });
            getProjectById();
            toastr.success("Employee removed successfully!");
        } catch (error) {
            console.error("Error adding vendor:", error);
            toastr.error("Failed to remove employee. Please try again.");
        } finally {
            handleResetModal();
            // setIsLoading(false); // Stop loader
        }
    }

    const addVendor = async () => {
        // await post('project/add-vendor', { ProjectID: id, UserID: vendorId });
        // getProjectById();

        // setIsLoading(true); // Start loader

        if (!vendorId) {
            toastr.error("Please select a vendor before adding."); // Validation message
            return;
        }

        const isVendorAlreadyAdded = vendorList.some(v => v.UserID == vendorId);

        if (isVendorAlreadyAdded) {
            toastr.error("This vendor is already added to the list."); // Validation message
            setVendorId("");
            return;
        }

        try {
            await post('project/add-vendor', { ProjectID: id, UserID: vendorId });
            await getProjectById(); // Refresh project data
            setVendorId("");
            toastr.success("Vendor added successfully!"); // Feedback to user
        } catch (error) {
            console.error("Error adding vendor:", error);
            toastr.error("Failed to add vendor. Please try again."); // Error message
        } finally {
            // setIsLoading(false); // Stop loader
        }
    }

    const deleteVendor = async (vendorId) => {
        try {
            await post('project/delete-vendor', { ProjectID: id, UserID: vendorId });
            await getProjectById();
            toastr.success("Vendor removed successfully!"); // Feedback to user
        } catch (error) {
            console.error("Error adding vendor:", error);
            toastr.error("Failed to removed vendor. Please try again."); // Error message
        } finally {
            handleResetModal();
            // setIsLoading(false); // Stop loader
        }
    }

    const [headCostList, setHeadCostList] = useState([]);

    const getHeadCostType = async () => {
        const res = await get('yearlyExpense/headcost-type/find');
        setHeadCostList(res);
    }

    useEffect(() => {
        getHeadCostType();
        getUsers();
        getProjectById();
        setUserRole(getLoggedInUserRole());
    }, []);

    // const headCostList = [
    //     "MoHAIT - 2300123234",
    //     "MoHAFIN - 23001236578",
    //     "MoHACON - 2300123455",
    //     "MoHAMAR - 2300124356"
    // ]

    const handleApproval = async (status) => {
        const data = {
            ProjectID: id,
            Status: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: Comment
        }

        await post(`project/create-approval`, data);
        setRejectModal(false);
        getProjectById();
    }

    const handleVoucherApproval = async (id, status) => {
        const data = {
            ID: id,
            Status: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: status
        }

        let url = "";
        
        if(userRole == 'Finance Officer'){
            url = 'paymentVoucher/create-approval-fo';
        }
        if(userRole == 'Examination Officer'){
            url = 'paymentVoucher/create-approval-eo';
        }
        else if(userRole == 'HOD'){
            url = 'paymentVoucher/create-approval-hod';
        }
        else if(userRole == 'Permanent Secretary'){
            url = 'paymentVoucher/create-approval-ps';
        }

        try{
            await post(url, data);
            getProjectById();
        } catch(e){
            toastr.error("Failed");
        }
    }

    const handleSubmit = async () => {
        const mydata = new FormData();
        mydata.append('Name', Name);
        mydata.append('Type', Type);
        mydata.append('HeadCostIDs', JSON.stringify(HeadCostIDs));
        mydata.append('Description', Description);
        mydata.append('Amount', Amount);
        mydata.append('PsID', PsID);
        mydata.append('EoID', EoID);
        mydata.append('hodID', hodID);
        mydata.append('deletedFiles', JSON.stringify(deletedFiles));

        for (let i = 0; i < files.length; i++) {
            mydata.append('mediaFiles', files[i]);
        }

        try{
            await putForm(`project/update/${id}`, mydata);
            toastr.success("Project updated successfully");
        } catch(e){
            toastr.error("Updated failed");
        }
        // navigate('/projects');
        getProjectById();
    }

    const handleRemoveMedia = (file) => {
        setDeletedFiles([...deletedFiles, file]);
        setMedia(media.filter(x => x.FilePath != file));
    }

    const handleMultiSelect = (selected) => {
        setHeadCostIDs(selected);
    };

    return (
        <React.Fragment>
            <DeleteModal isOpen={deleteEmployeeModal} onDelete={deleteEmployee} toggle={()=> setDeleteEmployeeModal(!deleteEmployeeModal)} item={tempDelete} />
            <DeleteModal isOpen={deleteVendorModal} onDelete={deleteVendor} toggle={()=> setDeleteVendorModal(!deleteVendorModal)} item={tempDelete} />
            <DeleteModal isOpen={deleteMilestoneModal} onDelete={deleteMilestone} toggle={()=> setDeleteMilestoneModal(!deleteMilestoneModal)} item={tempDelete} />

            <div className="page-content">
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <h5>Project Detail</h5>

                    <Row className="mb-4 mt-4">
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Col sm={4}>
                                    <Label for="" className="" style={{width: '100%'}}>Project Name</Label>
                                </Col>
                                <Col sm={8}>
                                    <Input type="text"
                                        placeholder="Project Name"
                                        id=""
                                        onChange={(e) => setName(e.target.value)}
                                        value={Name}
                                        disabled={disabledField}
                                        required>
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Col sm={4}>
                                        <Label for="" className="" style={{width: '100%'}}>Select Head Cost</Label>
                                    </Col>
                                    <Col sm={8}>
                                        {/* <Input type="select"
                                            placeholder="Select Head Cost"
                                            id=""
                                            onChange={(e) => setHeadCost(e.target.value)}
                                            value={HeadCost}
                                            required>
                                            <option>Select</option>
                                            {headCostList?.map((hostCost) => (
                                                <option value={hostCost.Name}>{hostCost.Name}</option>
                                            ))}
                                        </Input> */}
                                        <Select
                                            isMulti={true}
                                            isDisabled={disabledField}
                                            className="form-control"
                                            options={headCostList}
                                            value={HeadCostIDs}
                                            onChange={handleMultiSelect}
                                            getOptionLabel={(e)=> e.Name}
                                            getOptionValue={(e)=> e.ID} />
                                    </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Col sm={4}>
                                <Label for="" className="" style={{width: '100%'}}>Project Budget</Label>
                                </Col>
                                <Col sm={8}>
                                <Input type="number"
                                    placeholder="Project Budget"
                                    id=""
                                    onChange={(e) => setAmount(e.target.value)}
                                    value={Amount}
                                    disabled={disabledField}
                                    required>
                                </Input>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Col sm={2}>
                                <Label for="" className="" style={{width: '100%'}}>Project Description</Label>
                            </Col>
                            <Col sm={10}>
                                <Input type="textarea"
                                    placeholder="Project Description"
                                    id=""
                                    className="h-20"
                                    style={{width: '100%'}}
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={Description}
                                    disabled={disabledField}
                                    required>
                                </Input>
                            </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Col sm={4}>
                                <Label for="assignedEoID" className="" style={{width: '100%'}}>Assigned EO</Label>
                                </Col>
                                <Col sm={8}>
                                {eo && <Input type="select"
                                    placeholder="Assigned EO"
                                    id="assignedEoID"
                                    onChange={(e) => setEoID(e.target.value)}
                                    value={EoID}
                                    disabled={disabledField}
                                    required>
                                    <option>Select</option>
                                    {eo?.map((userEO) => (
                                        <option value={userEO.UserID}>{userEO.Name}</option>
                                    ))}
                                </Input>}
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Col sm={4}>
                                <Label for="assignedHOD" className="" style={{width: '100%'}}>Assigned HOD</Label>
                                </Col>
                                <Col sm={8}>
                                <Input type="select"
                                    placeholder="Assigned HOD"
                                    id="assignedHOD"
                                    onChange={(e) => setHodID(e.target.value)}
                                    value={hodID}
                                    disabled={disabledField}
                                    required>
                                    <option>Select</option>
                                    {hod?.map((userHOD) => (
                                        <option value={userHOD.UserID}>{userHOD.Name}</option>
                                    ))}
                                </Input>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Col sm={4}>
                                <Label for="assignedPS" className="" style={{width: '100%'}}>Assigned PS</Label>
                                </Col>
                                <Col sm={8}>
                                {ps && <Input type="select"
                                    placeholder="Assigned PS"
                                    id="assignedPS"
                                    onChange={(e) => setPsID(e.target.value)}
                                    value={PsID}
                                    disabled={disabledField}
                                    required>
                                    <option>Select</option>
                                    {ps?.map((userPS) => (
                                        <option value={userPS.UserID}>{userPS.Name}</option>
                                    ))}
                                </Input>}
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>

                    {userRole == 'Preparation Clerk' &&
                        (
                            <Row className="mt-4">
                                <Col sm={12}>
                                    <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                        <button onClick={handleSubmit} className="btn btn-primary">{project?.Status == 'Rejected' ? 'Submit for Approval' : 'Update'}</button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )
                    }

                    <Row className="mb-4 mt-4">
                        <h5 className="mb-4">Employee</h5>
                        {userRole == 'Preparation Clerk' &&
                            (
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCostName" className="me-4">Employee</Label>
                                            <Input type="select"
                                                placeholder="Employee"
                                                id="headCostName"
                                                onChange={(e) => setEmployeeId(e.target.value)}
                                                required>
                                                <option>Select</option>
                                                {employee.map(emp => (
                                                    <option value={emp.UserID}>{emp.Name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <button className="btn btn-primary" onClick={addEmployee}>Add</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        }


                        {/* <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="amount" className="me-4">Employee Name</Label>
                                    <Input type="text"
                                        placeholder="Employee Name"
                                        id="Amount"
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col> */}

                    </Row>

                    {employeeList.length > 0 ? (
                        <Row>
                            <Container className="border rounded p-2 mb-3">
                                {employeeList && employeeList.map((employee) => (
                                    <Container className="border rounded p-3 mb-3">
                                        {/* <h5>{employee.employee.Name}</h5> */}


                                        <Row>
                                            <Col sm={6}>
                                                <h5>{employee?.employee?.Name ?? ""}</h5>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                                    <button className="btn btn-danger"
                                                        disabled={disabledField} onClick={() => handleDeleteEmployeeModal(employee?.employee?.UserID)}>Delete</button>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        {/* <Row>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Vendor Type: </strong>
                                                    <span>Supplier</span>
                                                </Col>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Total Amount: </strong>
                                                    <span>$53600</span>
                                                </Col>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Total Amount: </strong>
                                                    <span>$3525</span>
                                                </Col>
                                            </Row> */}
                                        <Row>
                                            <Col sm={3} className="pt-2">
                                                <strong>Employee ID: </strong>
                                                <span>{employee?.employee?.UserPrefixId ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Employee Name: </strong>
                                                <span>{employee?.employee?.Name ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Department: </strong>
                                                <span>{employee?.employee?.Department ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Designation: </strong>
                                                <span>{employee?.employee?.Designation ?? ""}</span>
                                            </Col>
                                        </Row>
                                        {employee?.employee?.paymentVouchersEmployee?.length > 0 &&
                                        <Table bordered responsive className="mt-3">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Mile Stone</th>
                                                        <th>Payment Percentage</th> */}
                                                        <th>PV ID</th>
                                                        <th>Payment Amount</th>
                                                        <th>Payment Status</th>
                                                        {/* <th>PV ID</th> */}
                                                        <th>FO Approval</th>
                                                        <th>EO Approval</th>
                                                        <th>HOD Approval</th>
                                                        <th>PS Approval</th>
                                                        {(userRole == 'Permanent Secretary' || userRole == 'Finance Officer' || userRole == 'Preparation Clerk' || userRole == 'HOD' || userRole == 'Examination Officer') && <th>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {employee?.employee?.paymentVouchersEmployee?.map((employeeVoucher)=>(
                                                    <tr>
                                                        {/* <td>Milestone 1</td>
                                                        <td>25%</td> */}
                                                        <td>PV{employeeVoucher.ID}</td>
                                                        <td>${employeeVoucher.Amount}</td>
                                                        <td>{employeeVoucher.Status}</td>
                                                        {/* <td>PV{employeeVoucher.ID}</td> */}
                                                        <td>{employeeVoucher.FoStatus == 'Rejected' ? 'Re-Checked' : employeeVoucher.FoStatus}</td>
                                                        <td>{employeeVoucher.EoStatus == 'Rejected' ? 'Re-Checked' : employeeVoucher.EoStatus}</td>
                                                        <td>{employeeVoucher.HodStatus == 'Rejected' ? 'Re-Checked' : employeeVoucher.HodStatus}</td>
                                                        <td>{employeeVoucher.PsStatus == 'Rejected' ? 'Re-Checked' : employeeVoucher.PsStatus}</td>
                                                        {userRole == 'Finance Officer' && (employeeVoucher.FoStatus == "Pending" || employeeVoucher.FoStatus == "Rejected") &&
                                                            <td>
                                                                <button className="btn btn-primary" onClick={() => handleVoucherApproval(employeeVoucher.ID,"Approved")}>Process</button>
                                                                <button className="btn btn-danger mx-2" onClick={() => handleVoucherApproval(employeeVoucher.ID,"Rejected")}>Rework</button>
                                                            </td>
                                                        }
                                                        {userRole == 'Examination Officer' && (employeeVoucher.EoStatus == "Pending" || employeeVoucher.EoStatus == "Rejected") &&
                                                            <td>
                                                                <button className="btn btn-primary" disabled={employeeVoucher.FoStatus == 'Pending' || employeeVoucher.FoStatus == 'Rejected'} onClick={() => handleVoucherApproval(employeeVoucher.ID,"Approved")}>Process</button>
                                                                <button className="btn btn-danger mx-2" disabled={employeeVoucher.FoStatus == 'Pending' || employeeVoucher.FoStatus == 'Rejected'} onClick={() => handleVoucherApproval(employeeVoucher.ID,"Rejected")}>Rework</button>
                                                            </td>
                                                        }
                                                        {userRole == 'HOD' && (employeeVoucher.HodStatus == "Pending" || employeeVoucher.HodStatus == "Rejected") &&
                                                            <td>
                                                                <button className="btn btn-primary" disabled={employeeVoucher.EoStatus == 'Pending' || employeeVoucher.EoStatus == 'Rejected'} onClick={() => handleVoucherApproval(employeeVoucher.ID,"Approved")}>Process</button>
                                                                <button className="btn btn-danger mx-2" disabled={employeeVoucher.EoStatus == 'Pending' || employeeVoucher.EoStatus == 'Rejected'} onClick={() => handleVoucherApproval(employeeVoucher.ID,"Rejected")}>Rework</button>
                                                            </td>
                                                        }
                                                        {userRole == 'Permanent Secretary' && (employeeVoucher.PsStatus == "Pending" || employeeVoucher.PsStatus == "Rejected") &&
                                                            <td>
                                                                <button className="btn btn-primary" disabled={employeeVoucher.HodStatus == 'Pending' || employeeVoucher.HodStatus == 'Rejected'} onClick={() => handleVoucherApproval(employeeVoucher.ID,"Approved")}>Process</button>
                                                                <button className="btn btn-danger mx-2" disabled={employeeVoucher.HodStatus == 'Pending' || employeeVoucher.HodStatus == 'Rejected'} onClick={() => handleVoucherApproval(employeeVoucher.ID,"Rejected")}>Rework</button>
                                                            </td>
                                                        }
                                                        {/* {userRole == 'Permanent Secretary' && <td><button class="btn btn-primary">Process</button></td>} */}
                                                    </tr>))}
                                                </tbody>
                                            </Table>}
                                    </Container>))}
                            </Container>
                        </Row>
                    ) : (
                        <Row className="text-center">
                            <Col sm={12}>
                                <p>No employee assigned to this project</p>
                            </Col>
                        </Row>
                    )}


                    <Row className="mb-4 mt-4">
                        <h5 className="mb-4">Vendor</h5>
                        {userRole == 'Preparation Clerk' &&
                            (
                                <Row>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCostName" className="me-4">Vendor</Label>
                                            <Input
                                                type="select"
                                                placeholder="Vendor"
                                                id="headCostName"
                                                value={vendorId}
                                                onChange={(e) => setVendorId(e.target.value)}
                                                required>
                                                <option>Select</option>
                                                {vendor.map(ven => (
                                                    <option value={ven.UserID}>{ven.Name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <button className="btn btn-primary" onClick={addVendor}>Add</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        }

                    </Row>

                    {vendorList.length > 0 ? (
                        <Row>
                            <Container className="border rounded p-2 mb-3">
                                {vendorList && vendorList.map((vendor) => (
                                    <Container className="border rounded p-3 mb-3">
                                        {/* <h5>{vendor.vendor.Name}</h5> */}


                                        <Row>
                                            <Col sm={6}>
                                                <h5>{vendor?.vendor?.Name ?? ""}</h5>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                                    <button className="btn btn-primary mx-2"
                                                        disabled={disabledField} onClick={() => openMilestone(vendor)}>Add Milestone</button>
                                                    <button className="btn btn-danger"
                                                        disabled={disabledField} onClick={() => handleDeleteVendorModal(vendor?.vendor?.UserID)}>Delete</button>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col sm={3} className="pt-2">
                                                <strong>Vendor Address: </strong>
                                                <span>{vendor?.vendor?.Address ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Vendor Contact: </strong>
                                                <span>{vendor?.vendor?.ContactPerson ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Vendor Email: </strong>
                                                <span>{vendor?.vendor?.Email ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>RTP ID: </strong>
                                                <span>{vendor?.RTPID ?? ""}</span>
                                            </Col>
                                        </Row>
                                        {project?.milestoneProject?.filter(x=>x.ProjectVendorID == vendor?.ID)?.length > 0 &&
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Description</th>
                                                        <th>Due Date</th>
                                                        <th>Completed Date</th>
                                                        <th>Amount</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {project?.milestoneProject?.filter(x=>x.ProjectVendorID == vendor?.ID)?.map((headCostTypeRow)=>(
                                                        <tr>
                                                            <td>{headCostTypeRow?.ID}</td>
                                                            <td>{headCostTypeRow?.Name}</td>
                                                            <td>{headCostTypeRow?.Description}</td>
                                                            <td>{formatDateTime(headCostTypeRow?.DueDate)}</td>
                                                            <td>{formatDateTime(headCostTypeRow?.CompletedDate)}</td>
                                                            <td>{headCostTypeRow?.Amount}</td>
                                                            <td>{headCostTypeRow?.Status}</td>
                                                            <td>
                                                                <button className="btn btn-danger"
                                                                    disabled={disabledField} onClick={()=> handleDeleteMilestoneModal(headCostTypeRow.ID)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        }
                                        {vendor?.vendor?.paymentVouchersVendor?.length > 0 &&
                                        <Table bordered responsive className="mt-3">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Mile Stone</th>
                                                        <th>Payment Percentage</th> */}
                                                        <th>PV ID</th>
                                                        <th>Payment Amount</th>
                                                        <th>Payment Status</th>
                                                        {/* <th>PV ID</th> */}
                                                        <th>EO Approval</th>
                                                        <th>HOD Approval</th>
                                                        <th>PS Approval</th>
                                                        {(userRole == 'Permanent Secretary' || userRole == 'HOD' || userRole == 'Examination Officer') && <th>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {vendor?.vendor?.paymentVouchersVendor?.map((vendorVoucher)=>(
                                                    <tr>
                                                        {/* <td>Milestone 1</td>
                                                        <td>25%</td> */}
                                                        <td>PV{vendorVoucher.ID}</td>
                                                        <td>${vendorVoucher.Amount}</td>
                                                        <td>{vendorVoucher.Status}</td>
                                                        {/* <td>PV{vendorVoucher.ID}</td> */}
                                                        <td>{vendorVoucher.EoStatus == 'Rejected' ? 'Re-Checked' : vendorVoucher.EoStatus}</td>
                                                        <td>{vendorVoucher.HodStatus == 'Rejected' ? 'Re-Checked' : vendorVoucher.HodStatus}</td>
                                                        <td>{vendorVoucher.PsStatus == 'Rejected' ? 'Re-Checked' : vendorVoucher.PsStatus}</td>
                                                        {userRole == 'Examination Officer' && (vendorVoucher.EoStatus == "Pending" || vendorVoucher.EoStatus == "Rejected") &&
                                                            <td>
                                                                <button className="btn btn-primary" disabled={vendorVoucher.FoStatus == 'Pending' || vendorVoucher.FoStatus == 'Rejected'} onClick={() => handleVoucherApproval(vendorVoucher.ID,"Approved")}>Process</button>
                                                                <button className="btn btn-danger mx-2" disabled={vendorVoucher.FoStatus == 'Pending' || vendorVoucher.FoStatus == 'Rejected'} onClick={() => handleVoucherApproval(vendorVoucher.ID,"Rejected")}>Rework</button>
                                                            </td>
                                                        }
                                                        {userRole == 'HOD' && (vendorVoucher.HodStatus == "Pending" || vendorVoucher.HodStatus == "Rejected") &&
                                                            <td>
                                                                <button className="btn btn-primary" disabled={vendorVoucher.EoStatus == 'Pending' || vendorVoucher.EoStatus == 'Rejected'} onClick={() => handleVoucherApproval(vendorVoucher.ID,"Approved")}>Process</button>
                                                                <button className="btn btn-danger mx-2" disabled={vendorVoucher.EoStatus == 'Pending' || vendorVoucher.EoStatus == 'Rejected'} onClick={() => handleVoucherApproval(vendorVoucher.ID,"Rejected")}>Rework</button>
                                                            </td>
                                                        }
                                                        {userRole == 'Permanent Secretary' && (vendorVoucher.PsStatus == "Pending" || vendorVoucher.PsStatus == "Rejected") &&
                                                            <td>
                                                                <button className="btn btn-primary" disabled={vendorVoucher.HodStatus == 'Pending' || vendorVoucher.HodStatus == 'Rejected'} onClick={() => handleVoucherApproval(vendorVoucher.ID,"Approved")}>Process</button>
                                                                <button className="btn btn-danger mx-2" disabled={vendorVoucher.HodStatus == 'Pending' || vendorVoucher.HodStatus == 'Rejected'} onClick={() => handleVoucherApproval(employeeVoucher.ID,"Rejected")}>Rework</button>
                                                            </td>
                                                        }
                                                        {/* {userRole == 'Permanent Secretary' && <td><button class="btn btn-primary">Process</button></td>} */}
                                                    </tr>))}
                                                </tbody>
                                            </Table>}
                                    </Container>))}
                            </Container>
                        </Row>
                    ) : (
                        <Row className="text-center">
                            <Col sm={12}>
                                <p>No vendor assigned to this project</p>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <h5>Approvals</h5>
                        <Col sm={6}>
                            <Table bordered responsive>
                                <tbody>
                                    <tr>
                                        <td>{project?.ps?.Name}</td>
                                        <td>{project?.Status == 'Rejected' ? 'Re-Check' : project?.Status}</td>
                                        {userRole == 'Permanent Secretary' && (project.Status == "Pending") &&
                                            <td>
                                                <button className="btn btn-primary" onClick={() => handleApproval("Approved")}>Process</button>
                                                <button className="btn btn-danger mx-2" onClick={() => setRejectModal(true)}>Re-Check</button>
                                            </td>}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <h5>Activity Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Field</th>
                                        <th>Old Value</th>
                                        <th>New Value</th>
                                        <th>Date</th>
                                        <th>Created By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ActivityLogs && ActivityLogs?.map((log, i) => (
                                        <tr>
                                            <td>{log.ProjectLogID}</td>
                                            <td>{log.field}</td>
                                            <td>{log.oldValue}</td>
                                            <td>{log.newValue}</td>
                                            <td>{formatDateTime(log.changedAt)}</td>
                                            <td>{log.changedBy}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <h5>Approval Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        {/* <th>Log ID</th> */}
                                        <th>Approver Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ApprovalLogs && ApprovalLogs.map((approve) => (
                                        <tr>
                                            {/* <td>{approve.ApprovalLogID}</td> */}
                                            <td>{approve.approver.Name}</td>
                                            <td>{approve.ApprovalStatus == 'Rejected' ? 'Re-Checked' : approve.ApprovalStatus}</td>
                                            <td>{formatDateTime(approve.ApprovalDate)}</td>
                                            <td>{approve.Comments}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} style={{ marginBottom: "1rem" }}>
                            <h5>Media</h5>
                        </Col>

                        {userRole == 'Preparation Clerk' &&
                            (
                                <Row className="mt-4">
                                    <Col sm={10}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <label for="" class="me-5 form-label">Add Document</label>
                                            <Input
                                                type="file"
                                                multiple
                                                id=""
                                                placeholder="Project Description"
                                                className="h-25"
                                                onChange={handleFileChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                            <button onClick={handleSubmit} className="btn btn-primary">Save Document</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        }

                        <Col sm={12}>
                            <ul>
                                <Row>
                                    {media.map((file) => (
                                        <Col sm={3}>
                                            <li className="media-item">
                                                <span className="media-name">{file.FileName}</span>
                                                <a href={`${imageUrl+file.FilePath}`} download> <i className="fa fa-download"></i> </a>
                                                <button onClick={() => handleRemoveMedia(file.FilePath)} className="cross-button"><i className="fa fa-trash"></i></button>
                                            </li>
                                        </Col>
                                    ))}
                                </Row>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            <Modal isOpen={milestoneModal} toggle={closeMilestoneModal} size="lg">
                <ModalHeader className="mt-0" toggle={closeMilestoneModal}>Add Milestone</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="headcost" className="me-4">Select Head Cost</Label>
                                <Input
                                    id="headcost"
                                    type="select"
                                    value={selectedHeadCost}
                                    onChange={(e) => setSelectedHeadCost(e.target.value)}>
                                        <option value={""} selected disabled>Select Head Cost</option>
                                        {HeadCostIDs.map(hcost=>(
                                            <option value={hcost?.ID}>{hcost?.Name}</option>
                                        ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="milestoneName" className="me-4">Milestone Name</Label>
                                <Input
                                    id="milestoneName"
                                    type="text"
                                    value={selectedName}
                                    placeholder="Milestone Name"
                                    onChange={(e) => setSelectedName(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="milestoneDescription" className="me-4">Milestone Description</Label>
                                <Input
                                    id="milestoneDescription"
                                    type="text"
                                    value={milestoneDescription}
                                    placeholder="Milestone Description"
                                    onChange={(e) => setMilestoneDescription(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="ExpectedCompletionDate" className="me-4">Expected Completion Date</Label>
                                <Input
                                    id="ExpectedCompletionDate"
                                    type="date"
                                    value={expectedDate}
                                    placeholder="Expected Completion Date"
                                    onChange={(e) => setExpectedDate(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="ActualCompletionDate" className="me-4">Actual Completion Date</Label>
                                <Input
                                    id="ActualCompletionDate"
                                    type="date"
                                    value={completedDate}
                                    placeholder="Actual Completion Date"
                                    onChange={(e) => setCompletedDate(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="milestoneAmount" className="me-4">Milestone Amount</Label>
                                <Input
                                    id="milestoneAmount"
                                    type="number"
                                    value={selectedAmount}
                                    placeholder="Milestone Amount"
                                    onChange={(e) => setSelectedAmount(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Button color="primary" onClick={addMilestone}>Add</Button>
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Due Date</th>
                                        <th>Completion Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendorMilestones?.map((headCostTypeRow) => (
                                        <tr>
                                            <td>{headCostTypeRow?.ID}</td>
                                            <td>{headCostTypeRow?.Name}</td>
                                            <td>{headCostTypeRow?.Description}</td>
                                            <td>{formatDateTime(headCostTypeRow?.DueDate)}</td>
                                            <td>{formatDateTime(headCostTypeRow?.CompletedDate)}</td>
                                            <td>{headCostTypeRow?.Amount}</td>
                                            <td>{headCostTypeRow?.Status}</td>
                                            {/* <td>{headCostTypeRow?.ID}</td>
                                            <td>{headCostTypeRow?.Name}</td>
                                            <td>{headCostTypeRow?.Amount}</td> */}
                                            <td><button className="btn btn-danger" onClick={()=> deleteMilestone(headCostTypeRow?.ID)}>Delete</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm={12} className="text-end">
                            <button className="btn btn-primary mt-5" onClick={closeMilestoneModal}>Cancel</button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={rejectModal} toggle={() => setRejectModal(!rejectModal)} size="lg">
                <ModalHeader className="mt-0" toggle={() => setRejectModal(!rejectModal)}>Rejection</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-30">Comment</Label>
                                <textarea
                                    id="comment"
                                    value={Comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    rows="5"
                                    cols="200"
                                    placeholder="Enter your comment here..."
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="text-end">
                            <button className="btn btn-primary mx-2" onClick={()=>setRejectModal(false)}>Cancel</button>
                            <Button color="danger" onClick={() => handleApproval("Rejected")}>Re-Check</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            </div>
        </React.Fragment>
    );
};

export default ProjectDetail;
