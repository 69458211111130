import React, { useEffect, useMemo, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    ModalHeader
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put, putForm } from "helpers/api_helper";
import moment from 'moment';

import './voucher.css';
import ProjectDetail from "pages/Projects/ProjectDetail";

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const getYear = (data) => {
    return moment(data).format('YYYY');
}

const PaymentVoucherPrint = () => {
    document.title = "Payment Voucher";
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState('');
    const [data, setData] = useState({});
    const [project, setProject] = useState([]);
    const { id } = useParams();

    const [ProjectID, setProjectID] = useState();
    const [Name, setName] = useState('');
    const [Type, setType] = useState('');
    const [DisbursementAmount, setDisbursementAmount] = useState();
    const [Description, setDescription] = useState('');
    const [Amount, setAmount] = useState();
    const [HeadCost, setHeadCost] = useState('');
    const [ApprovalLogs, setApprovalLogs] = useState([]);

    const [media, setMedia] = useState([]);

    const [fo, setFo] = useState([]);
    const [eo, setEo] = useState([]);
    const [hod, setHod] = useState([]);
    const [ps, setPs] = useState([]);
    const [vendor, setVendor] = useState([]);

    const [EoStatus, setEoStatus] = useState('');
    const [HodStatus, setHodStatus] = useState('');
    const [PsStatus, setPsStatus] = useState('');

    const [FoID, setFoID] = useState('');
    const [EoID, setEoID] = useState('');
    const [hodID, setHodID] = useState('');
    const [PsID, setPsID] = useState('');
    const [VendorID, setVendorID] = useState();

    const [Status, setStatus] = useState('');

    const [createdByUser, setCreatedByUser] = useState({});

    const [projectData, setProjectData] = useState([]);
    const [projectDetail, setProjectDetail] = useState(null);

    const [VoucherType, setVoucherType] = useState();

    const [VendorList, setVendorList] = useState([]);

    const [deletedFiles, setDeletedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    const getPaymentVoucherById = async () => {
        const res = await get(`paymentVoucher/find/${id}`);
        setData(res);
        setProjectID(res.ProjectID);
        setType(res.Type);
        setDisbursementAmount(res.DisbursementAmount);
        setDescription(res.Description);
        setAmount(res.Amount);
        setVoucherType(res.VoucherType);
        setVendorID(res.VendorID);
        setFoID(res.FoID);
        setEoID(res.EoID);
        setHodID(res.hodID);
        setPsID(res.PsID);
        setEoStatus(res.EoStatus);
        setHodStatus(res.HodStatus);
        setPsStatus(res.PsStatus);
        setApprovalLogs(res.ApprovalLogs);
        setMedia(res.media);
        setStatus(res.Status);
        setCreatedByUser(res.createdByUser);
    }

    const getUsers = async () => {
        const res = await get('user/find');
        setEo(res.filter(x => x.role.Name == 'Examination Officer'));
        setFo(res.filter(x=> x.role.Name == 'Finance Officer'));
        setHod(res.filter(x => x.role.Name == 'HOD'));
        setPs(res.filter(x => x.role.Name == 'Permanent Secretary'));
        setVendor(res.filter(x => x.role.Name == 'Vendor'));
    }

    const getProject = async () => {
        const data = await get(`project/find`);
        setProjectData(data);
      }

    useEffect(() => {
        getUsers();
        getProject();
        getPaymentVoucherById();
        setUserRole(getLoggedInUserRole());
    }, []);

    useEffect(()=>{
        if(projectData?.length > 0){
            setProjectDetail(projectData?.find(x=>x.ProjectID == ProjectID));
            setTimeout(()=>{
                window.print();
            },1000);
        }
    },[ProjectID, projectData]);

    const [rejectModal, setRejectModal] = useState(false);
    const [Comment, setComment] = useState("");

    useEffect(()=>{
        const getVendors = async () => {
            const vendors = await get(`project/get-vendor-by-project/${ProjectID}`);
            setVendorList(vendors);
        } 
        if(ProjectID){
            getVendors();
        }
    },[ProjectID]);

    const numberToWords = (num) => {
        if (num === 0) return "Zero";
        
        const ones = [
            "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
            "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
        ];
        
        const tens = [
            "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
        ];
        
        const scales = ["", "Thousand", "Million", "Billion"];
        
        const convertHundreds = (n) => {
            let str = "";
            if (n >= 100) {
            str += ones[Math.floor(n / 100)] + " Hundred ";
            n %= 100;
            }
            if (n >= 20) {
            str += tens[Math.floor(n / 10)] + " ";
            n %= 10;
            }
            if (n > 0) {
            str += ones[n] + " ";
            }
            return str.trim();
        };
        
        let result = "";
        let scaleIndex = 0;
        
        while (num > 0) {
            let chunk = num % 1000;
            if (chunk > 0) {
            result = convertHundreds(chunk) + (scales[scaleIndex] ? " " + scales[scaleIndex] : "") + " " + result;
            }
            num = Math.floor(num / 1000);
            scaleIndex++;
        }
        
        return result.trim();
    };

    return (
        <React.Fragment>
            <div className="voucher-body">
                <h3 class="center-text">PAYMENT VOUCHER</h3>
                <p><strong>Executing Agency:</strong> {data?.createdByUser?.Agency || data?.ps?.Agency || data?.vendor?.Agency} <span style={{float: 'right'}}><strong>Payable at:</strong>{createdByUser && createdByUser?.role?.Name != 'Employee' ? data?.vendor?.Name : createdByUser?.Name}</span></p>
                <table style={{width: '80%'}}>
                    <tr>
                        <td><strong>Invoice Number</strong></td>
                        <td><strong>Register / Expense Voucher #</strong></td>
                        <td><strong>No. of Sub Voucher</strong></td>
                        <td><strong>Commitment / Obligation #</strong></td>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td>PV-{data?.ID}</td>
                        <td>51A-{getYear(data?.StartDate || data?.EndDate)}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                <br />
                <table style={{width: '80%'}}>
                    <tr>
                        <td><strong>Payee Information</strong></td>
                        <td><strong>Payee Name</strong></td>
                        <td><strong>Vendor Code</strong></td>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td></td>
                        <td>{data?.vendor?.Name || data?.createdByUser?.Name}</td>
                        <td>{data?.vendor?.UserID}</td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Description</strong></td>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td colspan="3">
                            {data?.Description}
                        </td>
                    </tr>
                </table>
                <br />
                <table>
                    <tr>
                        <th style={{width: '75px'}}>Fund <br/> 1100 </th>
                        <th style={{width: '95px'}}>Organisation Unit</th>
                        <th style={{width: '170px'}}>Activity</th>
                        <th style={{width: '120px'}}>Project Sub Component <br/> 000000000</th>
                        <th style={{width: '75px'}}>Location <br/> 1000</th>
                        <th style={{width: '75px'}}>Line Item</th>
                        <th>Particulars</th>
                        <th>Amount G$</th>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td></td>
                        <td>{createdByUser?.Agency}</td>
                        <td>{projectDetail?.Name}</td>
                        <td></td>
                        <td></td>
                        <td>1</td>
                        <td>{projectDetail?.Description}</td>
                        <td>{data?.Amount}</td>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{textAlign: 'left'}}><strong>Gross Amounts</strong></td>
                        <td>{data?.Amount}</td>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{textAlign: 'left'}}><strong>Deduction</strong></td>
                        <td>0</td>
                    </tr>
                    <tr style={{height: '20px'}}>
                        <td colspan="6"></td>
                        <td style={{textAlign: 'left'}}><strong>Net Amounts</strong></td>
                        <td>{data?.Amount}</td>
                    </tr>
                </table>
                <br />
                <p><strong>The above-mentioned goods & services were received in accordance with the specified contract or request for services.</strong></p>
                <div style={{width: '70%', float: 'left'}}>
                    <table style={{width: '90%'}}>
                        <tr style={{height: '20px'}}>
                            <td style={{borderStyle: 'none'}}></td>
                            <td style={{borderStyle: 'none'}}></td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>Department Head</td>
                        </tr>
                    </table>
                </div>
                <div style={{width: '70%', float: 'left'}}>
                    <br />
                    <p style={{width: '90%'}}>I certify that the above account for the gross payment of <strong>{numberToWords(data?.Amount)}</strong> Dollars is correct and was incurred under the appropriation items and that the rates or prices shown are according to regulations or contract fair and reasonable and that funds are available under the sub-heads affected to enable this payment to be made.</p>
                </div>
                <div style={{width: '30%', float: 'left'}}>
                    <p style={{lineBreak: 'auto', lineHeight: 2}}>Received Payment of Sum <strong>{numberToWords(data?.Amount)}</strong> Dollars</p>
                </div>
                <div style={{width: '70%', float: 'left'}}>
                    <table style={{width: '80%'}}>
                        <tr style={{height: '20px'}}>
                            <td style={{borderStyle: 'none'}}></td>
                            <td style={{borderStyle: 'none'}}></td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>Accounting Officer</td>
                        </tr>
                    </table>
                    <br/>
                </div>
                <div style={{width: '30%', float: 'left'}}>
                    <table>
                        <tr style={{height: '20px'}}>
                            <td style={{borderStyle: 'none'}}></td>
                            <td style={{borderStyle: 'none'}}></td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>Signature</td>
                        </tr>
                    </table>
                </div>
                <br />
                <table>
                    <tr>
                        <th>Tender Board Ref No.</th>
                        <th>Authority & File No</th>
                        <th>Examined and Passed for Payment By</th>
                        <th>Cheque No</th>
                        <th>Cheque Date</th>
                        <th>Witness to Payment and Date</th>
                    </tr>
                    <tr style={{height: '50px'}}>
                        <td></td>
                        <td></td>
                        <td>{data?.ps?.Name}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </React.Fragment>
    );
};

export default PaymentVoucherPrint;
