import React, { useEffect, useMemo, useState } from "react";
import {
  Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
  Label, Input, Pagination, PaginationItem, PaginationLink, ModalHeader,
  Tooltip
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { get, post, postForm } from "helpers/api_helper";
import moment from 'moment';
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx';

const formatDateTime = (isoDate) => {
  return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const KPIDefinition = () => {
  document.title = "KPI Definition";

  const getValue = (row, accessor) => {
    if (typeof accessor === "function") {
      return accessor(row);
    }
    return row[accessor] || "";
  };

  const [data, setData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [role, setRole] = useState([]);
  const [files, setFiles] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getUsers = async () => {
    const data = await get(`user/find`);
    setRole(data);
  }

  const getKPI = async () => {
    const data = await get(`kpi/find`);
    setData(data || []);
  }

  const getProject = async () => {
    const data = await get(`project/find`);
    setProjectData(data);
  }

  useEffect(() => {
    getKPI();
    getProject();
    getUsers();
  }, [])

  const [Name, setName] = useState("");

  const [KpiType, setKpiType] = useState("");
  const [Description, setDescription] = useState("");
  const [ProjectName, setProjectName] = useState("");

  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const [Department, setDepartment] = useState("");
  const [IndicatorType, setIndicatorType] = useState([]);

  const [TargetValue, setTargetValue] = useState(0);
  const [TargetType, setTargetType] = useState("");

  const startyear = 2024
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < (currentYear - 2015); i++) {
      years.push(startyear + i);
  }

  const [Year, SetYear] = useState(currentYear);

  const handleIndicator = () => {
    let arr = [...IndicatorType];
    arr.push({Name: Type, TargetValue, TargetType});
    setIndicatorType(arr);
    setType("");
    setTargetValue(0);
    setTargetType("");
  }

  const removeIndicator = (index) => {
    let arr = IndicatorType.filter((x, i) => i != index);
    setIndicatorType(arr);
  }

  const [ProjectID, setProjectID] = useState(null);
  const [Type, setType] = useState("")
  const [HodID, setHodID] = useState()

  const columns = useMemo(
    () => [
      {
        name: 'KPI ID',
        selector: (row) => `KPI-${row?.KPIDefinitionID}`,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'KPI Name',
        selector: (row) => row?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Created By',
        selector: (row) => row?.createdByUser?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Department',
        selector: row => row?.Department,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'HOD Assigned',
        selector: (row) => row?.hod?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Department Officer',
        selector: row => row?.do?.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Status',
        selector: row => row?.Status,
        sortable: true,
        enableSorting: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <Button
            color="primary"
            size="sm"
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const paginatedData = data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const navigate = useNavigate();

  const handleViewDetails = (rowData) => {
    navigate(`/kpi-definition-detail/${rowData.KPIDefinitionID}`);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const [KPIModal, setKPIModal] = useState(false);

  const addKPIDefinition = async () => {
    const data = {
      Name,
      Department,
      KpiType,
      ProjectName,
      Description,
      StartDate,
      EndDate,
      HodID,
      IndicatorType
    }
    const res = await post(`kpi/create`, data);
    setKPIModal(false);
    getKPI();
  }

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const add_PaymentVoucher = () => {
    setKPIModal(!KPIModal);
    removeBodyCss();
  };

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(getLoggedInUserRole());
  }, []);

  const filteredData = searchText.trim() === ""
    ? data // If searchText is empty, return all data without filtering
    : data.filter(
      (item) =>
        item?.Name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.HeadCost?.toString().includes(searchText) ||
        item?.Status?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.Amount?.toString().includes(searchText) ||
        (item.ps?.Name || "").toLowerCase().includes(searchText.toLowerCase())
    );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  function getTimestampForReport() {
    const now = new Date();
    
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  }

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData); // Convert data to sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Append the sheet to the workbook
    const reportName = `KPI_Definition_Export_${getTimestampForReport()}`;
    XLSX.writeFile(wb, reportName); // Export as .xlsx file
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <h5 style={{paddingTop: '20px'}}>Monitoring & Evaluation</h5>
        <p>Monitoring & Evaluation <b>&gt;</b> KPI Definition</p>
        <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
          <Row>
            <Col sm={6}><h5>KPI Definition<i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
            </h5>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>

            </Col>
            {(userRole == 'HOD' || userRole == 'SMD') && <Col sm={6} className="text-end"><Button color="primary" onClick={add_PaymentVoucher}> <i className="fa fa-plus"></i> Create </Button></Col>}

            <Modal isOpen={KPIModal} toggle={add_PaymentVoucher} size="lg">
              <ModalHeader className="mt-0" toggle={add_PaymentVoucher}>Create KPI Definition</ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm={12} sx={{mb:1}}>
                    <h5 className="mt-0">KPI Definition</h5>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">KPI Name</Label>
                      <Input
                        type="text"
                        id=""
                        placeholder="Enter Name"
                        onChange={(e) => setName(e.target.value)}
                        value={Name}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">Select Agency</Label>
                      <Input
                        type="select"
                        id=""
                        placeholder="Select Agency"
                        onChange={(e) => setDepartment(e.target.value)}
                        value={Department}
                      >
                        <option>Select Agency</option>
                        <option value={"MoHA"}>MoHA</option>
                        <option value={"Forensic"}>Forensic</option>
                        <option value={"GPF"}>GPF</option>
                        <option value={"GPS"}>GPS</option>
                        <option value={"GFS"}>GFS</option>
                        <option value={"GRO"}>GRO</option>
                        <option value={"CANU"}>CANU</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="hod" className="me-4 w-50">Assigned HOD</Label>
                      <Input
                        type="select"
                        id="hod"
                        onChange={(e) => setHodID(e.target.value)}
                        value={HodID}
                        placeholder="Assigned HOD">
                        <option>Select</option>
                        {role?.filter(x => x.role.Name == 'HOD' && (x.Agency == Department || x.Department == Department))?.map((user) => (
                          <option value={user.UserID}>{user.Name}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">Select KPI Type</Label>
                      <Input
                        type="select"
                        id=""
                        placeholder="Select KPI Type"
                        onChange={(e) => setKpiType(e.target.value)}
                        value={KpiType}
                      >
                        <option>Select KPI Type</option>
                        <option value={"Project"}>Project</option>
                        <option value={"Directives"}>Directives</option>
                        {/* {projectData.map((project)=>(
                          <option value={project.ProjectID}>{project.Name}</option>
                        ))} */}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">Project Name</Label>
                      <Input
                        type="text"
                        id=""
                        placeholder="Enter Project Name"
                        onChange={(e) => setProjectName(e.target.value)}
                        value={ProjectName}
                      />
                    </FormGroup>
                  </Col>
                  
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">KPI Description</Label>
                      <Input
                        type="text"
                        id=""
                        placeholder="Enter KPI Description"
                        onChange={(e) => setDescription(e.target.value)}
                        value={Description}
                      />
                    </FormGroup>
                  </Col>
                  
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">Start Date</Label>
                      <Input
                        type="date"
                        id=""
                        placeholder="Enter Start Date"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={StartDate}
                      />
                    </FormGroup>
                  </Col>
                  
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">End Date</Label>
                      <Input
                        type="date"
                        id=""
                        placeholder="Enter End Date"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={EndDate}
                      />
                    </FormGroup>
                  </Col>

                  <hr/>

                  <Col sm={12}>
                    <h5 className="mt-0">Add Indicator Target</h5>
                  </Col>

                  {/* <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                        <Label for="year" className="me-4 w-25">Year</Label>
                        <Input type="select"
                            placeholder="Year"
                            id="year"
                            onChange={(e)=>SetYear(e.target.value)}
                            value={Year}
                            required>
                                <option selected>Select Year</option>
                                {years.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                        </Input>
                    </FormGroup>
                  </Col> */}

                  <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-25">Indicator Type</Label>
                      <Input
                        type="text"
                        id=""
                        placeholder="Indicator Type"
                        onChange={(e) => setType(e.target.value)}
                        value={Type}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-25">Target Type</Label>
                      <Input
                        type="select"
                        id=""
                        placeholder="Target Type"
                        onChange={(e) => setTargetType(e.target.value)}
                        value={TargetType}
                      >
                        <option selected>Select Type</option>
                        <option value={'Number'}>Number</option>
                        <option value={'Percentage'}>Percentage</option>
                        <option value={'Amount'}>Amount</option>
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-25">Yearly Target</Label>
                      <Input
                        type="number"
                        id=""
                        placeholder="Target"
                        onChange={(e) => setTargetValue(e.target.value)}
                        value={TargetValue}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={12}>
                    <button className="btn btn-primary fa-pull-right mb-2" onClick={handleIndicator}>Add</button>
                  </Col>
                  
                  <Col sm={12}>
                    <div style={{border: '1px solid #ececf1', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '10px', paddingTop: 0}}>
                      {IndicatorType && IndicatorType.map((indicator, index) => (
                        <Col sm={12} style={{backgroundColor: 'white', padding: '10px', border: '1px solid #ececf1', marginTop: '10px'}}>
                          <Row>
                            <Col sm={10}>
                              <Row>
                                  <Col sm={6} style={{margin: 0}}>
                                      <h6 className="float-left">Name: &nbsp;</h6><span className="float-left">{indicator?.Name}</span>
                                  </Col>
                                  <Col sm={6} style={{margin: 0}}>
                                      <h6 className="float-left">Year: &nbsp;</h6><span className="float-left">{indicator?.Year}</span>
                                  </Col>
                                  <Col sm={6} style={{margin: 0}}>
                                      <h6 className="float-left">Type: &nbsp;</h6><span className="float-left">{indicator?.TargetType}</span>
                                  </Col>
                                  <Col sm={6} style={{margin: 0}}>
                                      <h6 className="float-left">Yearly Target: &nbsp;</h6><span className="float-left">{indicator?.TargetValue || 0}</span>
                                  </Col>
                              </Row>
                            </Col>
                            <Col sm={2} style={{margin: 0}}>
                              <h6 className="fa-pull-right" style={{cursor: 'pointer'}} onClick={()=> removeIndicator(index)}><i className="fa fa-times" style={{color: '#ec4561'}}></i></h6>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </div>
                  </Col>
                  
                  <Col sm={12} className="text-end">
                    <button className="btn btn-primary mt-5" onClick={addKPIDefinition}>Submit</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

          </Row>

          <Row className="mt-4">
            <Col sm={6}>
              <div className="table-subheader">
                <span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                </span>
              </div>
            </Col>
            <Col sm={6}>
              <button className="btn btn-success" style={{float: "right"}} onClick={handleExport}>Export</button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                highlightOnHover
                striped
                responsive
              />
            </Col>
          </Row>
          <Row>

            <Pagination style={{ float: "right" }}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={handlePrevPage} />
              </PaginationItem>

              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={currentPage == index + 1} key={index}>
                  <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={handleNextPage} />
              </PaginationItem>
            </Pagination>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default KPIDefinition;
