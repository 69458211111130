import React, { useEffect, useMemo, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    ModalHeader
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put, putForm } from "helpers/api_helper";
import moment from 'moment';

import './project.css';

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const PaymentVoucherDetail = () => {
    document.title = "Payment Voucher";
    const navigate = useNavigate();

    const extractBaseUrl = (url) => {
        const parsedUrl = new URL(url);
        return `${parsedUrl.protocol}//${parsedUrl.hostname}/`;
      };
    const imageUrl = extractBaseUrl(process.env.REACT_APP_API_URL);
    
    const [userRole, setUserRole] = useState('');
    const [data, setData] = useState({});
    const [project, setProject] = useState([]);
    const { id } = useParams();

    const [ProjectID, setProjectID] = useState();
    const [Name, setName] = useState('');
    const [Type, setType] = useState('');
    const [DisbursementAmount, setDisbursementAmount] = useState();
    const [Description, setDescription] = useState('');
    const [Amount, setAmount] = useState();
    const [ApprovedAmount, setApprovedAmount] = useState();
    const [HeadCost, setHeadCost] = useState('');
    const [ApprovalLogs, setApprovalLogs] = useState([]);

    const [media, setMedia] = useState([]);

    const [fo, setFo] = useState([]);
    const [eo, setEo] = useState([]);
    const [hod, setHod] = useState([]);
    const [ps, setPs] = useState([]);
    const [vendor, setVendor] = useState([]);

    const [EoStatus, setEoStatus] = useState('');
    const [HodStatus, setHodStatus] = useState('');
    const [PsStatus, setPsStatus] = useState('');

    const [FoID, setFoID] = useState('');
    const [EoID, setEoID] = useState('');
    const [hodID, setHodID] = useState('');
    const [PsID, setPsID] = useState('');
    const [VendorID, setVendorID] = useState();

    const [Status, setStatus] = useState('');

    const [createdByUser, setCreatedByUser] = useState({});

    const [projectData, setProjectData] = useState([]);

    const [VoucherType, setVoucherType] = useState();

    const [VendorList, setVendorList] = useState([]);

    const [deletedFiles, setDeletedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    const getPaymentVoucherById = async () => {
        const res = await get(`paymentVoucher/find/${id}`);
        setData(res);
        setProjectID(res.ProjectID);
        setType(res.Type);
        setDisbursementAmount(res.DisbursementAmount);
        setDescription(res.Description);
        setAmount(res.Amount);
        setVoucherType(res.VoucherType);
        setVendorID(res.VendorID);
        setFoID(res.FoID);
        setEoID(res.EoID);
        setHodID(res.hodID);
        setPsID(res.PsID);
        setEoStatus(res.EoStatus);
        setHodStatus(res.HodStatus);
        setPsStatus(res.PsStatus);
        setApprovalLogs(res.ApprovalLogs);
        setMedia(res.media);
        setStatus(res.Status);
        setCreatedByUser(res.createdByUser);
    }

    const getUsers = async () => {
        const res = await get('user/find');
        setEo(res.filter(x => x.role.Name == 'Examination Officer'));
        setFo(res.filter(x=> x.role.Name == 'Finance Officer'));
        setHod(res.filter(x => x.role.Name == 'HOD'));
        setPs(res.filter(x => x.role.Name == 'Permanent Secretary'));
        setVendor(res.filter(x => x.role.Name == 'Vendor'));
    }

    const getProject = async () => {
        const data = await get(`project/find`);
        setProjectData(data);
    }

    useEffect(() => {
        getUsers();
        getProject();
        getPaymentVoucherById();
        setUserRole(getLoggedInUserRole());
    }, []);

    const [rejectModal, setRejectModal] = useState(false);
    const [Comment, setComment] = useState("");

    const handleApproval = async (status) => {
        const data = {
            ID: id,
            Status: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: Comment || status
        }

        let url = "";
        
        if(userRole == 'Finance Officer'){
            url = 'paymentVoucher/create-approval-fo';
        }
        if(userRole == 'Examination Officer'){
            url = 'paymentVoucher/create-approval-eo';
        }
        else if(userRole == 'HOD'){
            url = 'paymentVoucher/create-approval-hod';
        }
        else if(userRole == 'Permanent Secretary'){
            url = 'paymentVoucher/create-approval-ps';
        }

        await post(url, data);
        setComment("");
        getPaymentVoucherById();
        setRejectModal(false);
    }

    const handleSubmit = async () => {
        // const user = getLoggedInUser();
        // const data = {
        //     Name, Type, Description, Amount, HeadCost,
        //     PsID,
        //     EoID,
        //     hodID,
        //     deletedFiles
        // }

        const mydata = new FormData();
        mydata.append('ProjectID', ProjectID);
        mydata.append('Type', Type);
        mydata.append('VoucherType', VoucherType);
        mydata.append('Amount', Amount);
        mydata.append('DisbursementAmount', DisbursementAmount);
        mydata.append('Description', Description);
        if(createdByUser.role.Name !== 'Employee'){
            mydata.append('VendorID', VendorID);
        }
        mydata.append('FoID', FoID);
        mydata.append('PsID', PsID);
        mydata.append('EoID', EoID);
        mydata.append('hodID', hodID);
        mydata.append('Status', Status);
        mydata.append('deletedFiles', JSON.stringify(deletedFiles));

        for (let i = 0; i < files.length; i++) {
            mydata.append('mediaFiles', files[i]);
        }

        await putForm(`paymentVoucher/update/${id}`, mydata);
        // navigate('/payment-voucher');
        getPaymentVoucherById();
    }

    const handleRemoveMedia = (file) => {
        setDeletedFiles([...deletedFiles, file]);
        setMedia(media.filter(x => x.FilePath != file));
    }

    const printVoucher = () => {
        navigate(`/payment-voucher-print/${id}`)
    }

    useEffect(()=>{
        const getVendors = async () => {
            const vendors = await get(`project/get-vendor-by-project/${ProjectID}`);
            setVendorList(vendors);
        } 
        if(ProjectID){
            getVendors();
        }
    },[ProjectID]);

    return (
        <React.Fragment>

            <div className="page-content">
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>                
                    <Row>
                        <Col sm={6}>
                            <h5>Payment Voucher Detail</h5>
                        </Col>
                        <Col sm={6}>
                            {data && data.PsStatus === 'Approved' && <button className="btn btn-primary" style={{float: 'right'}} onClick={printVoucher}>Print Voucher</button>}
                        </Col>
                    </Row>

                    <Row className="mb-4 mt-4">
                        {data?.VoucherType == 'Development' && <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-1 w-50">Project</Label>
                                <Input type="select"
                                    placeholder="Project"
                                    id=""
                                    onChange={(e) => setProjectID(e.target.value)}
                                    value={ProjectID}
                                    required>
                                        <option>Select Project</option>
                                        {projectData.map((project)=>(
                                        <option value={project.ProjectID}>{project.Name}</option>
                                        ))}
                                </Input>
                            </FormGroup>
                        </Col>}
                            
                        {userRole == 'Employee' && <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-50">Select Voucher Type</Label>
                                <Input
                                type="select"
                                id=""
                                placeholder="Voucher Type"
                                onChange={(e) => setVoucherType(e.target.value)}
                                value={VoucherType}
                                >
                                <option>Select</option>
                                <option value={'Development'}>Development</option>
                                <option value={'Non-Development'}>Non-Development</option>
                                </Input>
                            </FormGroup>
                        </Col>}
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-1 w-50">Payment Amount</Label>
                                <Input type="number"
                                    placeholder="Payment Amount"
                                    id=""
                                    onChange={(e) => setAmount(e.target.value)}
                                    value={Amount}
                                    required>
                                </Input>
                            </FormGroup>
                        </Col>
                        
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="disbursementAmount" className="me-4 w-50">Approved Voucher Amount</Label>
                                <Input
                                    type="number"
                                    id="disbursementAmount"
                                    placeholder="Approved Voucher Amount"
                                    onChange={(e) => setDisbursementAmount(e.target.value)}
                                    value={DisbursementAmount}
                                    disabled={userRole != 'Preparation Clerk'}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4">Payment Description</Label>
                                <Input type="textarea"
                                    placeholder="Payment Description"
                                    id=""
                                    className="h-20"
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={Description}
                                    required>
                                </Input>
                            </FormGroup>
                        </Col>
                        {createdByUser && createdByUser?.role?.Name != 'Employee' && <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="vendor" className="me-1 w-50">Vendor</Label>
                            <Input
                                type="select"
                                id="vendor"
                                placeholder="Vendor"
                                onChange={(e) => setVendorID(e.target.value)}
                                value={VendorID}>
                                    <option>Select</option>
                                    {VendorList?.map((user) => (
                                        <option value={user.UserID}>{user.Name}</option>
                                    ))}
                            </Input>
                            </FormGroup>
                        </Col>}
                        {/* {createdByUser && createdByUser?.role?.Name == 'Employee' &&  */}
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="fo" className="me-1 w-50">Finance Officer</Label>
                            <Input
                                type="select"
                                id="fo"
                                placeholder="Finance Officer"
                                onChange={(e) => setFoID(e.target.value)}
                                value={FoID}>
                                <option>Select</option>
                                {fo?.map((user) => (
                                <option value={user.UserID}>{user.Name}</option>
                                ))}
                            </Input>
                            </FormGroup>
                        </Col>
                        {/* } */}
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedEoID" className="me-1 w-50">Assigned EO</Label>
                                {eo && <Input type="select"
                                    placeholder="Assigned EO"
                                    id="assignedEoID"
                                    onChange={(e) => setEoID(e.target.value)}
                                    value={EoID}
                                    required>
                                    <option>Select</option>
                                    {eo?.map((userEO) => (
                                        <option value={userEO.UserID}>{userEO.Name}</option>
                                    ))}
                                </Input>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedHOD" className="w-50">Assigned HOD</Label>
                                <Input type="select"
                                    placeholder="Assigned HOD"
                                    id="assignedHOD"
                                    onChange={(e) => setHodID(e.target.value)}
                                    value={hodID}
                                    required>
                                    <option>Select</option>
                                    {hod?.map((userHOD) => (
                                        <option value={userHOD.UserID}>{userHOD.Name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedPS" className="w-50">Assigned PS</Label>
                                {ps && <Input type="select"
                                    placeholder="Assigned PS"
                                    id="assignedPS"
                                    onChange={(e) => setPsID(e.target.value)}
                                    value={PsID}
                                    required>
                                    <option>Select</option>
                                    {ps?.map((userPS) => (
                                        <option value={userPS.UserID}>{userPS.Name}</option>
                                    ))}
                                </Input>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="status" className="w-50">Status</Label>
                                <Input type="select"
                                    placeholder="Select Status"
                                    id="status"
                                    disabled={PsStatus == 'Pending' || PsStatus == 'Rejected'}
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={Status}
                                    required>
                                    <option>Select</option>
                                    <option>Pending</option>
                                    <option>Ready for Dispatch</option>
                                    <option>Cheque Printed</option>
                                    <option>Cheque Disbursed</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    {userRole == 'Finance Officer' &&
                        (
                            <Row className="mt-4">
                                <Col sm={12}>
                                    <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                        <button onClick={handleSubmit} className="btn btn-primary">{data?.FoStatus == 'Rejected' || data?.EoStatus == 'Rejected' || HodStatus == 'Rejected' || PsStatus == 'Rejected' ? 'Submit for Approval' : 'Save'}</button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )
                    }

                    <Row>
                        <h5>Approvals</h5>
                        <Col sm={6}>
                            <Table bordered responsive>
                                <tbody>
                                    {/* {createdByUser && createdByUser?.role?.Name == 'Employee' && */}
                                    <tr>
                                        <td>{data?.fo?.Name}</td>
                                        <td>{data?.FoStatus == 'Rejected' ? 'Re-Checked' : data?.FoStatus}</td>
                                        {userRole == 'Finance Officer' && (data.FoStatus == "Pending") &&
                                            <td>
                                                <button className="btn btn-primary" onClick={() => handleApproval("Approved")}>Approve</button>
                                                <button className="btn btn-danger mx-2" onClick={() => setRejectModal(true)}>Re-Check</button>
                                            </td>
                                        }
                                    </tr>
                                    {/* } */}
                                    <tr>
                                        <td>{data?.eo?.Name}</td>
                                        <td>{data?.EoStatus == 'Rejected' ? 'Re-Checked' : data?.EoStatus}</td>
                                        {userRole == 'Examination Officer' && (data.EoStatus == "Pending") &&
                                            <td>
                                                <button className="btn btn-primary" disabled={data.FoStatus == 'Pending' || data.FoStatus == 'Rejected'} onClick={() => handleApproval("Approved")}>Approve</button>
                                                <button className="btn btn-danger mx-2" disabled={data.FoStatus == 'Pending' || data.FoStatus == 'Rejected'} onClick={() => setRejectModal(true)}>Re-Check</button>
                                            </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>{data?.hod?.Name}</td>
                                        <td>{data?.HodStatus == 'Rejected' ? 'Re-Checked' : data?.HodStatus}</td>
                                        {userRole == 'HOD' && (data.HodStatus == "Pending") &&
                                            <td>
                                                <button className="btn btn-primary" disabled={data.EoStatus == 'Pending' || data.EoStatus == 'Rejected'} onClick={() => handleApproval("Approved")}>Approve</button>
                                                <button className="btn btn-danger mx-2" disabled={data.EoStatus == 'Pending' || data.EoStatus == 'Rejected'} onClick={() => setRejectModal(true)}>Re-Check</button>
                                            </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>{data?.ps?.Name}</td>
                                        <td>{data?.PsStatus == 'Rejected' ? 'Re-Checked' : data?.PsStatus}</td>
                                        {userRole == 'Permanent Secretary' && (data.PsStatus == "Pending") &&
                                            <td>
                                                <button className="btn btn-primary" disabled={data.HodStatus == 'Pending' || data.HodStatus == 'Rejected'} onClick={() => handleApproval("Approved")}>Approve</button>
                                                <button className="btn btn-danger mx-2" disabled={data.HodStatus == 'Pending' || data.HodStatus == 'Rejected'} onClick={() => setRejectModal(true)}>Re-Check</button>
                                            </td>
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <h5>Approval Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        {/* <th>Log ID</th> */}
                                        <th>Approver Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ApprovalLogs && ApprovalLogs.map((approve) => (
                                        <tr>
                                            {/* <td>{approve.ApprovalLogID}</td> */}
                                            <td>{approve.approver.Name}</td>
                                            <td>{approve.ApprovalStatus == 'Rejected' ? 'Re-Checked' : approve?.ApprovalStatus}</td>
                                            <td>{formatDateTime(approve.ApprovalDate)}</td>
                                            <td>{approve.Comments}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} style={{ marginBottom: "1rem" }}>
                            <h5>Media</h5>
                        </Col>

                        {userRole == 'Preparation Clerk' &&
                            (
                                <Row className="mt-4">
                                    <Col sm={10}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <label for="" class="me-5 form-label">Add Document</label>
                                            <Input
                                                type="file"
                                                multiple
                                                id=""
                                                placeholder="Project Description"
                                                className="h-25"
                                                onChange={handleFileChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                            <button onClick={handleSubmit} className="btn btn-primary">Save Document</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        }
                        <Col sm={12}>
                            <ul>
                                <Row>
                                    {media.map((file) => (
                                        <Col sm={3}>
                                            <li className="media-item">
                                                <span className="media-name">{file.FileName}</span>
                                                <a href={`${imageUrl+file.FilePath}`} download> <i className="fa fa-download"></i> </a>
                                                <button onClick={() => handleRemoveMedia(file.FilePath)} className="cross-button"><i className="fa fa-trash"></i></button>
                                            </li>
                                        </Col>
                                    ))}
                                </Row>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={rejectModal} toggle={() => setRejectModal(!rejectModal)} size="lg">
                <ModalHeader className="mt-0" toggle={() => setRejectModal(!rejectModal)}>Rejection</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-30">Comment</Label>
                                <textarea
                                    id="comment"
                                    value={Comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    rows="5"
                                    cols="200"
                                    placeholder="Enter your comment here..."
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="text-end">
                            {/* <button className="btn btn-primary mt-5" onClick={addVendor}>Submit</button> */}
                            <Button color="primary" onClick={() => handleApproval("Rejected")}>Re-Check</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default PaymentVoucherDetail;
