import React, { useEffect, useMemo, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Tooltip,
    ModalHeader,
    ModalFooter
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { del, get, post, put, putForm } from "helpers/api_helper";
import moment from 'moment';
import toastr from "toastr";

import './project.css';

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const fDate = (isoDate) => {
    return moment(isoDate).format('YYYY-MM-DD');
};

const KPIDefinitionDetail = () => {
    document.title = "KPI Definition Detail";

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    const [kpiCloseModal, setKpiCloseModal] = useState(false);

    const [Status, setStatus] = useState("");

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };
    const [userRole, setUserRole] = useState('');
    const [userId, setUserId] = useState();
    const [data, setData] = useState({});
    const { id } = useParams();

    const [selectedIndicator, setSelectedIndicator] = useState({});

    const [Department, setDepartment] = useState();
    const [IndicatorType, setIndicatorType] = useState([]);
    const [ProjectID, setProjectID] = useState(null);
    const [role, setRole] = useState([]);
    const [Name, setName] = useState('');
    const [Type, setType] = useState('');
    const [TargetType, setTargetType] = useState("");
    
    const [KpiType, setKpiType] = useState("");
    const [Description, setDescription] = useState("");
    const [ProjectName, setProjectName] = useState("");

    const [ClosedDate, setClosedDate] = useState("");

    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");

    const [selectedName, setSelectedName] = useState("");
    const [selectedTarget, setSelectedTarget] = useState(0);
    const [selectedTargetType, setSelectedTargetType] = useState("");
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedDescription, setSelectedDescription] = useState("");

    const [HodID, setHodID] = useState('');

    const [projectData, setProjectData] = useState([]);

    const [TargetValue, setTargetValue] = useState(0);

    const [expandedIndicator, setExpandedIndicator] = useState(null);
    const [expandedTarget, setExpandedTarget] = useState(null);

    const toggleExpand = (indicatorId) => {
        setExpandedIndicator((prev) => (prev === indicatorId ? null : indicatorId));
    };

    const toggleExpandTarget = (targetId) => {
        setExpandedTarget((prev) => (prev === targetId ? null : targetId));
    };

    useEffect(()=>{
        console.log(StartDate)
    },[StartDate])

    const [rejectModal, setRejectModal] = useState(false);
    const [Comment, setComment] = useState("");
    const [tempId, setTempId] = useState(null);

    
  const [KPIModal, setKPIModal] = useState(false);

    const getKPIDefinitionByID = async () => {
        const res = await get(`kpi/find/${id}`);
        setData(res);
        setName(res?.Name);
        setDepartment(res?.Department);
        setKpiType(res?.KpiType);
        setProjectName(res?.ProjectName);
        setDescription(res?.Description);
        setStartDate(res?.StartDate);
        setEndDate(res?.EndDate); 
        setIndicatorType(res?.kpi);
        setHodID(res?.HodID);
        setStatus(res?.Status);
        setClosedDate(res?.ClosedDate);
        // setApprovalLogs(res.ApprovalLogs);
        // setStatus(res.Status);
        // setCreatedByUser(res.createdByUser);
    }

    const closeKPI = async () => {
        const res = await put(`kpi/close-kpi/${id}`);
        setKpiCloseModal(false);
        getKPIDefinitionByID();
    }

    const getUsers = async () => {
        const res = await get('user/find');
        setRole(res);
        // setEo(res.filter(x => x.role.Name == 'Examination Officer'));
        // setFo(res.filter(x=> x.role.Name == 'Finance Officer'));
        // setHod(res.filter(x => x.role.Name == 'HOD'));
        // setPs(res.filter(x => x.role.Name == 'Permanent Secretary'));
        // setVendor(res.filter(x => x.role.Name == 'Vendor'));
    }

    const getProject = async () => {
        const data = await get(`project/find`);
        setProjectData(data);
      }

    useEffect(() => {
        getUsers();
        getProject();
        getKPIDefinitionByID();
        setUserRole(getLoggedInUserRole());
        setUserId(getLoggedInUser()?.id);
    }, []);

    const handleDefinitionApproval = async (status) => {
        const data = {
            ID: id,
            Status: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: Comment || status
        }

        let url = 'kpi/definition-approval';

        await post(url, data);
        setComment("");
        getKPIDefinitionByID();
    }

    const removeIndicator = async () => {
        await del(`kpi/indicator/delete/${tempDelete?.KPIIndicatorTypeID}`);
        setTempDelete(null);
        getKPIDefinitionByID();
        setDeleteModal(false);
    }

    const handleRejectModal = (id) => {
        setRejectModal(true);
        setTempId(id);
    }

    const handleIndicatorApproval = async (id, status) => {
        const data = {
            ID: id,
            Status: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: Comment || status
        }

        let url = 'kpi/indicator-approval';

        await post(url, data);
        setComment("");
        setTempId(null);
        setRejectModal(false);
        getKPIDefinitionByID();        
    }

    const [tempDelete, setTempDelete] = useState();

    const handleDeleteModal = (indicator) => {
        setTempDelete(indicator);
        setDeleteModal(true);
    }

    const handleUpdateModal = (indicator) => {
        setSelectedIndicator(indicator);
        setSelectedName(indicator?.Name);
        setSelectedValue(indicator?.Value);
        setSelectedTarget(indicator?.TargetValue);
        setSelectedTargetType(indicator?.TargetType);
        setSelectedDescription(indicator?.Description);
        setKPIModal(true);
    }

    const startyear = 2024
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < (currentYear - 2015); i++) {
        years.push(startyear + i);
    }

  const [Year, SetYear] = useState(currentYear);

    const addKpiIndicator = async () => {
        if(Status == 'Closed') return;    
        const mydata = {KPIDefinitionID: id, Name: Type, TargetValue, TargetType};
        await post(`kpi/indicator/create`, mydata);
        setType("");
        getKPIDefinitionByID();
    }

    const updateKpiIndicator = async () => {    
        if(Status == 'Closed') return;    
        const mydata = {Value: selectedValue, Description: selectedDescription};
        await put(`kpi/indicator/update/${selectedIndicator?.KPIIndicatorTypeID}`, mydata);
        getKPIDefinitionByID();
        setKPIModal(false);
    }

    const handleUpdate = async () => {

        const mydata = { Name, Department, HodID, Description, ProjectName, KpiType, StartDate, EndDate }
        try{
            await put(`kpi/update/${id}`, mydata);
            toastr.success("Kpi Updated Successfully");
            getKPIDefinitionByID();
        } catch(e){
            console.log(e.message);
        }
    }

    return (
        <React.Fragment>

            <div className="page-content">
                <h5 style={{paddingTop: '20px'}}>Monitoring & Evaluation</h5>
                <p>Monitoring & Evaluation <b>&gt;</b> KPI Definition</p>
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <Row>
                        <Col sm={6}>
                            <h5>
                                KPI Definition Detail
                                <i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
                            </h5>

                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target="pageHeading"
                                toggle={toggleTooltip}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
                            </Tooltip>

                            </Col>
                    </Row>

                    <Row className="mb-4 mt-4">
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="" className="me-4 mb-0 w-50">KPI Name</Label>
                            <Input
                                type="text"
                                id=""
                                placeholder="Enter Name"
                                onChange={(e) => setName(e.target.value)}
                                value={Name}
                            />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="" className="me-4 mb-0 w-50">Select Agency</Label>
                            <Input
                                type="select"
                                id=""
                                placeholder="Select Agency"
                                onChange={(e) => setDepartment(e.target.value)}
                                value={Department}
                            >
                                <option>Select Agency</option>
                                <option value={"MoHA"}>MoHA</option>
                                <option value={"Forensic"}>Forensic</option>
                                <option value={"GPF"}>GPF</option>
                                <option value={"GPS"}>GPS</option>
                                <option value={"GFS"}>GFS</option>
                                <option value={"GRO"}>GRO</option>
                                <option value={"CANU"}>CANU</option>
                            </Input>
                            </FormGroup>
                        </Col>
                        
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="hod" className="me-4 mb-0 w-50">Assigned HOD</Label>
                            <Input
                                type="select"
                                id="hod"
                                onChange={(e) => setHodID(e.target.value)}
                                value={HodID}
                                placeholder="Assigned HOD">
                                <option>Select</option>
                                {role?.filter(x => x.role.Name == 'HOD' && (x.Department == Department || x.Agency == Department))?.map((user) => (
                                <option value={user.UserID}>{user.Name}</option>
                                ))}
                            </Input>
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="" className="me-4 mb-0 w-50">Select KPI Type</Label>
                            <Input
                                type="select"
                                id=""
                                placeholder="Select KPI Type"
                                onChange={(e) => setKpiType(e.target.value)}
                                value={KpiType}
                            >
                                <option>Select KPI Type</option>
                                <option value={"Project"}>Project</option>
                                <option value={"Directives"}>Directives</option>
                                {/* {projectData.map((project)=>(
                                    <option value={project.ProjectID}>{project.Name}</option>
                                ))} */}
                            </Input>
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-50">Project Name</Label>
                                <Input
                                type="text"
                                id=""
                                placeholder="Enter Project Name"
                                onChange={(e) => setProjectName(e.target.value)}
                                value={ProjectName}
                                />
                            </FormGroup>
                        </Col>
                        
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-50">KPI Description</Label>
                                <Input
                                type="text"
                                id=""
                                placeholder="Enter KPI Description"
                                onChange={(e) => setDescription(e.target.value)}
                                value={Description}
                                />
                            </FormGroup>
                        </Col>
                        
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-50">Start Date</Label>
                                <Input
                                type="date"
                                id=""
                                placeholder="Enter Start Date"
                                onChange={(e) => setStartDate(e.target.value)}
                                value={fDate(StartDate)}
                                />
                            </FormGroup>
                        </Col>
                        
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-50">End Date</Label>
                                <Input
                                type="date"
                                id=""
                                placeholder="Enter End Date"
                                onChange={(e) => setEndDate(e.target.value)}
                                value={fDate(EndDate)}
                                />
                            </FormGroup>
                        </Col>
                        
                        {Status == 'Closed' &&
                            <>
                            <Col sm={6}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Status</Label>
                                    <Input
                                    type="text"
                                    disabled
                                    readOnly
                                    id=""
                                    placeholder="Status"
                                    value={Status} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="" className="me-4 w-50">Closed Date</Label>
                                    <Input
                                    type="date"
                                    disabled
                                    readOnly
                                    id=""
                                    placeholder="Closed Date"
                                    value={fDate(ClosedDate)} />
                                </FormGroup>
                            </Col>
                            </>
                        }
                    </Row>

                    {data.createdBy == userId &&
                        (
                            <Row className="mt-4">
                                <Col sm={12}>
                                    <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                        <button onClick={handleUpdate} className="btn btn-primary">Save</button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )
                    }

                    <Row>
                        <Col sm={12}>
                            <h5 className="mt-0">KPI Indicator Target</h5>
                        </Col>

                        {data.createdBy == userId && 
                        <>
                        <Col sm={6} style={{alignContent:'center'}}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="" className="me-4 mb-0">Indicator Type</Label>
                            <Input
                                type="text"
                                id=""
                                placeholder="Indicator Type"
                                onChange={(e) => setType(e.target.value)}
                                value={Type}
                            />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="targetType" className="me-4 mb-0">Target Type</Label>
                            <Input
                                type="select"
                                id="targetType"
                                placeholder="Indicator Type"
                                onChange={(e) => setTargetType(e.target.value)}
                                value={TargetType}
                            >
                                <option selected>Select Type</option>
                                <option value={'Number'}>Number</option>
                                <option value={'Percentage'}>Percentage</option>
                                <option value={'Amount'}>Amount</option>
                            </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="target" className="me-4 mb-0">Target</Label>
                            <Input
                                type="text"
                                id="target"
                                placeholder="Indicator Type"
                                onChange={(e) => setTargetValue(e.target.value)}
                                value={TargetValue}
                            />
                            </FormGroup>
                        </Col>
                        {/* <Col sm={5}>
                        <FormGroup className="d-flex align-items-center text-nowrap">
                            <Label for="year" className="me-4 mb-0">Year</Label>
                            <Input type="select"
                                placeholder="Year"
                                id="year"
                                onChange={(e)=>SetYear(e.target.value)}
                                value={Year}
                                required>
                                    <option selected>Select Year</option>
                                    {years.map(year => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                            </Input>
                        </FormGroup>
                        </Col> */}
                        <Col sm={1}>
                            <button className="btn btn-primary fa-pull-right mb-2" disabled={Status == 'Closed'} onClick={addKpiIndicator}>Add</button>
                        </Col>
                        </>}

                        <Col sm={12}>
                            <div style={{border: '1px solid #ececf1', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '10px', paddingTop: 0}}>
                            {IndicatorType && IndicatorType.map((indicator, index) => (
                                <Row style={{margin: 0}}>
                                    <Col sm={data.createdBy == userId ? 10 : 12} style={{backgroundColor: 'white', padding: '10px', border: '1px solid #ececf1', marginTop: '10px'}}>
                                        <Row>
                                            <Col sm={9}>
                                                <Row>
                                                    <Col sm={6} style={{margin: 0}}>
                                                        <h6 className="float-left">Name: &nbsp;</h6><span className="float-left">{indicator?.Name}</span>
                                                    </Col>
                                                    {/* <Col sm={6} style={{margin: 0}}>
                                                        <h6 className="float-left">Year: &nbsp;</h6><span className="float-left">{indicator?.Year}</span>
                                                    </Col> */}
                                                    <Col sm={6} style={{margin: 0}}>
                                                        <h6 className="float-left">Target Type: &nbsp;</h6><span className="float-left">{indicator?.TargetType}</span>
                                                    </Col>
                                                    <Col sm={6} style={{margin: 0}}>
                                                        <h6 className="float-left">Yearly Target: &nbsp;</h6><span className="float-left">{indicator?.TargetValue || 0}</span>
                                                    </Col>
                                                    <Col sm={6} style={{margin: 0}}>
                                                        <h6 className="float-left">Actual Value: &nbsp;</h6><span className="float-left">{indicator?.Value || 0}</span>
                                                    </Col>
                                                    <Col sm={12} style={{margin: 0}}>
                                                        <h6 className="float-left">DO Comment: &nbsp;</h6><span className="float-left">{indicator?.Description || 0}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={3} style={{margin: 0}}>
                                                {data.createdBy == userId && <button className="btn btn-default fa-pull-right mx-3" onClick={()=> handleDeleteModal(indicator)}><i className="fa fa-times" style={{color: '#ec4561'}}></i></button>}
                                                {userRole == 'Department Officer' && <button className="btn btn-primary w-100 mb-2" disabled={Status == 'Closed'} onClick={()=>handleUpdateModal(indicator)}>Update</button>}
                                                {/* {data.createdBy != userId && <><button className="btn btn-default w-100" disabled>{indicator.Status}</button><p className="w-100">{formatDateTime(indicator.updatedDate || indicator.createdDate)}</p></>} */}
                                                <button className="btn btn-default w-100" disabled>{indicator.Status}</button><p className="w-100">{formatDateTime(indicator.updatedDate || indicator.createdDate)}</p>
                                                <button className={`btn btn-warning mb-2 w-100 ${expandedIndicator == indicator?.KPIIndicatorTypeID ? 'active': ''}`} onClick={()=>toggleExpand(indicator?.KPIIndicatorTypeID)}>{expandedIndicator == indicator?.KPIIndicatorTypeID ? "Hide":"View"} Approval Logs</button>
                                                <button className={`btn btn-info w-100 ${expandedTarget == indicator?.KPIIndicatorTypeID ? 'active': ''}`} onClick={()=>toggleExpandTarget(indicator?.KPIIndicatorTypeID)}>{expandedTarget == indicator?.KPIIndicatorTypeID ? "Hide":"View"} Target Logs</button>
                                            </Col>
                                            {expandedTarget === indicator?.KPIIndicatorTypeID && (
                                                <div style={{ marginTop: "10px", padding: "10px" }}>
                                                    
                                                <hr/>

                                                <Row>
                                                    <Col sm={12}>
                                                        <h5>Target Logs</h5>
                                                        <Table bordered responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    {/* <th>Year</th> */}
                                                                    <th>Target</th>
                                                                    <th>Actual</th>
                                                                    <th>DO Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {indicator?.kpiIndicatorLog && indicator?.kpiIndicatorLog?.map((target) => (
                                                                    <tr>
                                                                        <td>{formatDateTime(target.createdDate)}</td>
                                                                        {/* <td>{target.Year}</td> */}
                                                                        <td>{target.TargetValue}</td>
                                                                        <td>{target.Value}</td>
                                                                        <td>{target.Description}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                                </div>
                                            )}
                                            {expandedIndicator === indicator?.KPIIndicatorTypeID && (
                                                <div style={{ marginTop: "10px", padding: "10px" }}>
                                                    
                                                <hr/>

                                                <Row>
                                                    <Col sm={12}>
                                                        <h5>Approval Logs</h5>
                                                        <Table bordered responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>Approver Name</th>
                                                                    <th>Status</th>
                                                                    <th>Date</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {indicator?.ApprovalLogs && indicator?.ApprovalLogs?.map((approve) => (
                                                                    <tr>
                                                                        <td>{approve.approver.Name}</td>
                                                                        <td>{approve.ApprovalStatus}</td>
                                                                        <td>{formatDateTime(approve.ApprovalDate)}</td>
                                                                        <td>{approve.Comments}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                                </div>
                                            )}
                                        </Row>
                                    </Col>
                                    {data.createdBy == userId && <Col sm={2} style={{marginTop: '20px'}}>
                                    {indicator?.Status == 'Approved' &&
                                        <>
                                            <button className="btn btn-success w-100" disabled>Approved</button>
                                            <p>{formatDateTime(indicator.updatedDate)}</p>
                                        </>
                                    }
                                    {indicator?.Status == 'Rejected' &&
                                        <>
                                            <button className="btn btn-danger w-100" disabled>Rejected</button>
                                            <p>{formatDateTime(indicator.updatedDate)}</p>
                                        </>
                                    }
                                    {(indicator?.Status == 'Pending' || indicator?.Status == 'Rejected') &&
                                            <Row>
                                                <Col sm={6}>
                                                    <button className="btn btn-primary" onClick={()=> handleIndicatorApproval(indicator?.KPIIndicatorTypeID, "Approved")}>Process</button>
                                                </Col>
                                                <Col sm={6}>
                                                    <button className="btn btn-danger mx-2" onClick={()=> handleRejectModal(indicator?.KPIIndicatorTypeID)}>Rework</button>
                                                </Col>
                                            </Row>
                                    }
                                    </Col>}
                                </Row>
                            ))}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {userRole == 'SMD' && <button className="mt-2 float-right btn btn-danger" style={{float: 'right'}} disabled={Status == 'Closed'} onClick={()=> setKpiCloseModal(true)}>{Status == 'Closed' ? 'KPI Closed' : 'Close KPI'}</button>}
                        </Col>
                    </Row>
                    {/* <Row>
                        <h5>Approvals</h5>
                        <Col sm={6}>
                            <Table bordered responsive>
                                <tbody>
                                    <tr>
                                        <td>{data?.createdByUser?.Name}</td>
                                        <td>{data?.Status}</td>
                                        {data.createdBy == userId &&
                                            <td>
                                                <button className="btn btn-primary" onClick={() => handleDefinitionApproval("Approved")}>Approve</button>
                                                <button className="btn btn-danger mx-2" onClick={() => handleDefinitionApproval("Rejected")}>Reject</button>
                                            </td>
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row> */}

                    {/* <Row>
                        <Col sm={12}>
                            <h5>Approval Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>Approver Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ApprovalLogs && ApprovalLogs.map((approve) => (
                                        <tr>
                                            <td>{approve.approver.Name}</td>
                                            <td>{approve.ApprovalStatus}</td>
                                            <td>{formatDateTime(approve.ApprovalDate)}</td>
                                            <td>{approve.Comments}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row> */}
                    <Modal isOpen={kpiCloseModal} toggle={()=>setKpiCloseModal(false)}>
                        <ModalHeader toggle={()=>setKpiCloseModal(false)}>Confirm KPI Closure</ModalHeader>
                        <ModalBody>
                        Are you sure you want to close this KPI?
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={()=>setKpiCloseModal(false)}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={closeKPI}>
                            Close KPI
                        </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={deleteModal} toggle={()=>setDeleteModal(false)}>
                        <ModalHeader toggle={()=>setDeleteModal(false)}>Confirm Deletion</ModalHeader>
                        <ModalBody>
                        Are you sure you want to delete <strong>{tempDelete?.Name}</strong>?
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={()=>setDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={removeIndicator}>
                            Delete
                        </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={KPIModal} toggle={()=>setKPIModal(false)} size="lg">
                        <ModalHeader className="mt-0" toggle={()=> setKPIModal(false)}>Update KPI Detail</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm={12} sx={{mb:1}}>
                                    <h5 className="mt-0">KPI Definition</h5>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="name" className="me-4 w-50">Name</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        placeholder="Indicator Name"
                                        disabled
                                        value={selectedName}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="targetType" className="me-4 w-50">Target Type</Label>
                                    <Input
                                        type="text"
                                        id="targetType"
                                        disabled
                                        placeholder="Target Type"
                                        value={selectedTargetType}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="target" className="me-4 w-50">Yearly Target</Label>
                                    <Input
                                        type="number"
                                        id="target"
                                        disabled
                                        placeholder="Target Value"
                                        value={selectedTarget}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="value" className="me-4 w-50">Actual Value</Label>
                                    <Input
                                        type="number"
                                        id="value"
                                        placeholder="Enter Value"
                                        onChange={(e)=> setSelectedValue(e.target.value)}
                                        value={selectedValue}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="value" className="me-4 w-25">DO Comment</Label>
                                    <textarea
                                        id="value"
                                        value={selectedDescription}
                                        onChange={(e) => setSelectedDescription(e.target.value)}
                                        rows="5"
                                        cols="200"
                                        placeholder="Description..."
                                        style={{borderColor: '#e9ecef'}}
                                    />
                                    </FormGroup>
                                </Col>
                  
                                <Col sm={12} className="text-end">
                                    <button className="btn btn-primary mt-5" onClick={updateKpiIndicator}>Submit</button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>

                    
                </Container>
            </div>

            <Modal isOpen={rejectModal} toggle={() => setRejectModal(!rejectModal)} size="lg">
                <ModalHeader className="mt-0" toggle={() => setRejectModal(!rejectModal)}>Rejection</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-30">Comment</Label>
                                <textarea
                                    id="comment"
                                    value={Comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    rows="5"
                                    cols="200"
                                    placeholder="Enter your comment here..."
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="text-end">
                            {/* <button className="btn btn-primary mt-5" onClick={addVendor}>Submit</button> */}
                            <Button color="primary" onClick={() => handleIndicatorApproval(tempId, "Rejected")}>Rework</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default KPIDefinitionDetail;
