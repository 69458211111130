import { del, get, post, put, putForm } from "helpers/api_helper";
import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Table, Row, Col, Container, Button, Modal, ModalHeader, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Alert
} from "reactstrap";
import moment from 'moment';
import toastr from "toastr";
import DeleteModal from "components/DeleteModal";

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const UpdateYearlyExpense = () => {
    document.title = "Update Yearly Expense";
    const navigate = useNavigate();

    const extractBaseUrl = (url) => {
        const parsedUrl = new URL(url);
        return `${parsedUrl.protocol}//${parsedUrl.hostname}/`;
      };
    const imageUrl = extractBaseUrl(process.env.REACT_APP_API_URL);

    const { id } = useParams();
    const [psList, setPsList] = useState([]);
    const [data, setData] = useState({});

    const [modal, setModal] = useState(false);

    const handleHeadCostModal = () => {
        setModal(!modal);
    }

    const getPS = async () => {
        const res = await get('user/find-by-role-name/Permanent Secretary');
        setPsList(res);
    }

    const [Agency, setAgency] = useState('');
    const [ApprovalUserID, setApprovalUserID] = useState();
    const [TotalExpense, setTotalExpense] = useState("");

    const [totalHeadCost, setTotalHeadCost] = useState("");

    const [ApprovalLogs, setApprovalLogs] = useState([]);

    const [hcId, setHcId] = useState(0);
    const [hcName, setHcName] = useState("");
    const [hcAmount, setHcAmount] = useState("");

    const [costError, setCostError] = useState("");

    const [Comment, setComment] = useState('');

    const [BudgetYear, setBudgetYear] = useState();

    const [headCosts, setHeadCosts] = useState([]);

    const [headCostType, setHeadCostType] = useState('');
    const [headCostCode, setHeadCostCode] = useState('');
    const [headCostDescription, setHeadCostDescription] = useState("");

    const [headCostList, setHeadCostList] = useState([]);

    const [type, setType] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);

    const [media, setMedia] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [ActivityLogs, setActivityLogs] = useState([]);

    const [transferHcModal, setTransferHcModal] = useState(false);
    const [headCostFrom, setHeadCostFrom] = useState();
    const [headCostTo, setHeadCostTo] = useState();
    const [transferAmount, setTransferAmount] = useState(0);
    // const headCostList = [
    //     "MoHAIT - 2300123234",
    //     "MoHAFIN - 23001236578",
    //     "MoHACON - 2300123455",
    //     "MoHAMAR - 2300124356"
    // ];


    const startyear = 2024
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < (currentYear - 2015); i++) {
        years.push(`July ${startyear + i} to June ${startyear + i + 1}`);
    }

    useEffect(() => {
        setTimeout(() => {
            setCostError("");
        }, 3000);
    }, [costError]);

    const getYearlyExpenseById = async () => {
        const getData = await get(`yearlyExpense/find/${id}`);
        setData(getData);
        setAgency(getData?.Agency);
        setApprovalUserID(getData?.ApprovalUserID);
        setTotalExpense(getData?.TotalExpense);
        setBudgetYear(getData?.BudgetYear);
        setApprovalLogs(getData?.ApprovalLogs);
        setTotalHeadCost(getData?.headCost?.reduce((total, acc) => total + parseFloat(acc.Amount), 0));
        setMedia(getData?.media);
        setActivityLogs(getData?.yearlyExpenseHistory);
    }

    const getHeadCostType = async () => {
        const res = await get('yearlyExpense/headcost-type/find');
        setHeadCostList(res);
    }

    useEffect(() => {
        getHeadCostType();
        getPS();
        getYearlyExpenseById();
    }, []);

    
    const [deleteModal, setDeleteModal] = useState(false);
    const [tempDelete, setTempDelete] = useState();
    const handleDeleteModal = (data) => {
        setTempDelete(data);
        setDeleteModal(true);
    }

    const deleteHeadCostType = async (id) => {
        await del(`yearlyExpense/headcost-type/delete/${id}`);
        getHeadCostType();
        setDeleteModal(false);
    }

    const addHeadCostType = async () => {
        const data = {
            Name : headCostType,
            Code : headCostCode,
            Description: headCostDescription
        };

        await post('yearlyExpense/headcost-type/create', data);
        getHeadCostType();
    }

    const handleSubmit = async () => {
        const user = getLoggedInUser();
        const data = new FormData();
        data.append("BudgetYear", BudgetYear);
        data.append("Agency", Agency);
        data.append("TotalExpense", TotalExpense);
        data.append("ApprovalUserID", ApprovalUserID);
        data.append("ApprovalStatus", 'Pending');
        data.append("CreatedBy", user.id);
        data.append('deletedFiles', JSON.stringify(deletedFiles));

        for (let i = 0; i < files.length; i++) {
            data.append('mediaFiles', files[i]);
        }

        await putForm(`yearlyExpense/update/${id}`, data);
        navigate('/yearly-expense');
    }

    const resetHeadCost = () => {
        setIsEdit(false);
        setHcId(0);
        setHcName("");
        setType("");
        setHcAmount(0);
    }

    const updateHeadCost = (data) => {
        setIsEdit(true);
        setHcId(data?.HeadCostID);
        setHcName(data?.Name);
        setType(data?.Type);
        setHcAmount(data?.Amount);
    }

    const handleHeadCost = async () => {
        if(!hcName || !hcAmount || !type) {
            toastr.error("All fields are required", "Error");
            return;
        };
        let tempCost = parseFloat(totalHeadCost) + parseFloat(hcAmount);
        if (tempCost > parseFloat(TotalExpense)) {
            setCostError(`Expense cannot exceed ${TotalExpense}`);
            return
        }

        const temp = headCostList.find(x=> x.Name == hcName);

        const payload = {
            ID: parseInt(hcId),
            Name: hcName,
            Code: temp?.Code,
            Description: temp?.Description,
            Type: type,
            Amount: parseFloat(hcAmount),
            YearlyExpenseID: parseInt(id)
        }

        if(isEdit){
            const d = data?.headCost.find(x=> x.HeadCostID == hcId);
            if(d.Name != hcName && data?.headCost?.some(obj => obj.Name == hcName)){
                toastr.error(`${hcName} is already in the list`,"Error");
                return;
            }
            await put(`yearlyExpense/update-headcost/${hcId}`, payload);
        }
        else{
            if(data?.headCost?.some(obj => obj.Name == hcName)){
                toastr.error(`${hcName} is already in the list`,"Error");
                return;
            }
            await post(`yearlyExpense/create-headcost`, payload);
        }
        resetHeadCost();
        getYearlyExpenseById();
    }

    const [reject_modal, setreject_modal] = useState(false);

    const handleApproval = async (status) => {
        const data = {
            YearlyExpenseID: id,
            ApprovalStatus: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: Comment
        }

        await post(`yearlyExpense/create-approval`, data);
        getYearlyExpenseById();
        setreject_modal(false);
    }

    const handleRejectClick = () => {
        //setreject_modal(true);
        setreject_modal(!reject_modal);
    };

    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        setUserRole(getLoggedInUserRole());
    }, []);
    
    const [tempId, setTempId] = useState();
    
    const openConfirmModal = (i) => {
        setTempId(i);
        setConfirmModal(true);
    }

    const handleRemoveHeadCost = async (id) => {
        await del(`yearlyExpense/delete-headcost/${id}`);
        getYearlyExpenseById();
        setConfirmModal(false);
    }

    const closeConfirmModal = () => {
        setTempId(null);
        setConfirmModal(false);
    }

    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    const handleRemoveMedia = (file) => {
        setDeletedFiles([...deletedFiles, file]);
        setMedia(media.filter(x => x.FilePath != file));
    }

    const handleChange = (value) => {
        const newValue = Number(value);
        const maxValue = data?.headCost?.find(x=> x.HeadCostID == headCostFrom)?.Amount || 0

        if(newValue >= 0 && newValue <= maxValue){
            setTransferAmount(parseInt(newValue));
        }
        else{
            setTransferAmount(parseInt(maxValue));
        }
    }

    const resetTransferModal = () => {
        setHeadCostFrom(null);
        setHeadCostTo(null);
        setTransferAmount(0);
    }

    const handleTransfer = async () => {
        const maxValue = data?.headCost?.find(x=> x.HeadCostID == headCostFrom)?.Amount || 0
        if(transferAmount > parseInt(maxValue)){
            toastr.error(`Amount cannot exceed ${maxValue}`);
            return;
        }

        const payload = {
            headCostFrom,
            headCostTo,
            transferAmount
        };
        try{
            await post('yearlyExpense/transfer-headcost', payload);
            toastr.success("Amount transfer successfully");
            getYearlyExpenseById();
            resetTransferModal();
            setTransferHcModal(false);
        } catch(error){
            toastr.error("Unable to transfer");
        }
    }

    return (
        <React.Fragment>
            <DeleteModal isOpen={deleteModal} item={tempDelete} toggle={()=> setDeleteModal(false)} onDelete={deleteHeadCostType} />
            <Modal isOpen={reject_modal} toggle={() => setreject_modal(!reject_modal)} size="lg">
                <ModalHeader className="mt-0" toggle={() => setreject_modal(!reject_modal)}>Reject Yearly Expense</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-30">Comment</Label>
                                {/* <Input
                                    type="text"
                                    id=""
                                    placeholder="Type here..."
                                /> */}
                                <textarea
                                    id="comment"
                                    value={Comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    rows="5"
                                    cols="200"
                                    placeholder="Enter your comment here..."
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="text-end">
                            {/* <button className="btn btn-primary mt-5" onClick={addVendor}>Submit</button> */}
                            <Button color="primary" onClick={() => handleApproval("Rejected")}>Re-Check</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <div className="page-content">
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    {/* <h5>Single Yearly Expense</h5> */}

                    <Row className="mb-4 mt-4">
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedPS" className="me-4">Budget Year</Label>
                                {(years) && <Input type="select"
                                    placeholder="Enter Type of ID"
                                    id="selectAgency"
                                    onChange={(e) => setBudgetYear(e.target.value)}
                                    value={BudgetYear}
                                    required>
                                    <option selected>Select Year</option>
                                    {years?.map(year => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </Input>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="totalExpense" className="me-4">Total Expense</Label>
                                <Input type="text"
                                    placeholder="Total Expense"
                                    id="totalExpense"
                                    onChange={(e) => setTotalExpense(e.target.value)}
                                    value={TotalExpense}
                                    required>
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col sm={12} style={{ marginBottom: "1rem" }}>
                            <h5>Media</h5>
                        </Col>

                        {userRole == 'Finance Officer' &&
                            (
                                <Row className="mt-4">
                                    <Col sm={10}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <label for="" class="me-5 form-label">Add Document</label>
                                            <Input
                                                type="file"
                                                multiple
                                                id=""
                                                placeholder="Project Description"
                                                className="h-25"
                                                onChange={handleFileChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {/* <Col sm={2}>
                                        <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                            <button onClick={handleSubmit} className="btn btn-primary">Save Document</button>
                                        </FormGroup>
                                    </Col> */}
                                </Row>
                            )
                        }
                        <Col sm={12}>
                            <ul>
                                <Row>
                                    {media?.map((file) => (
                                        <Col sm={3}>
                                            <li className="media-item">
                                                <span className="media-name">{file.FileName}</span>
                                                <a href={`${imageUrl+file.FilePath}`} download> <i className="fa fa-download"></i> </a>
                                                <button onClick={() => handleRemoveMedia(file.FilePath)} className="cross-button"><i className="fa fa-trash"></i></button>
                                            </li>
                                        </Col>
                                    ))}
                                </Row>
                            </ul>
                        </Col>
                        {userRole == 'Finance Officer' &&
                            (
                                <Col sm={12}>
                                    <FormGroup className="d-flex text-nowrap" style={{placeContent:'end'}}>
                                        <button onClick={handleSubmit} className="btn btn-primary">{data.ApprovalStatus == 'Rejected' ? 'Submit for Approval' : 'Update'}</button>
                                    </FormGroup>
                                </Col>
                            )
                        }
                    </Row>
                    <Row className={userRole == 'Finance Officer' ? "mb-4 mt-4" : "mb-1 mt-1"}>
                        <h5 className={userRole == 'Finance Officer' ? "mb-4" : "mb-3"}>Head Cost</h5>
                        {/* <Col sm={3}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="headCostID" className="me-4">Head Cost ID</Label>
                                    <Input type="text"
                                        placeholder="Head Cost ID"
                                        id="headCostID"
                                        onChange={(e)=> setHcId(e.target.value)}
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col> */}
                        {userRole == 'Finance Officer' &&
                            (
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCost" className="me-4">Select Head Cost</Label>
                                            <Input type="select"
                                                placeholder="Head Cost"
                                                id="headCost"
                                                onChange={(e) => setHcName(e.target.value)}
                                                value={hcName}
                                                required>
                                                <option>Select</option>
                                                {headCostList?.map((hostCost) => (
                                                    <option value={hostCost.Name}>{hostCost.Name}</option>
                                                ))}
                                            </Input>
                                            <button className="btn btn-primary mx-2" onClick={()=>setModal(true)}><i className="fa fa-cog"></i></button>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="type" className="me-4">Type</Label>
                                            <Input type="select"
                                                placeholder="type"
                                                id="type"
                                                onChange={(e)=> setType(e.target.value)}
                                                value={type}
                                                required>
                                                    <option selected disabled value={""}>Select</option>
                                                    <option value={"Development"}>Development</option>
                                                    <option value={"Non-Development"}>Non Development</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="amount" className="me-4">Amount</Label>
                                            <Input type="text"
                                                placeholder="Amount"
                                                id="Amount"
                                                onChange={(e) => setHcAmount(e.target.value)}
                                                value={hcAmount}
                                                required>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            {userRole == 'Finance Officer' && 
                                            <>
                                                <button className="btn btn-primary" onClick={handleHeadCost}>{isEdit ? "Update":"Add"} Headcost</button>
                                                {isEdit && <button className="btn btn-default" onClick={resetHeadCost}>Clear</button>}
                                            </>}
                                        </FormGroup>
                                    </Col>
                                </Row>

                            )
                        }

                        <Modal isOpen={modal} toggle={handleHeadCostModal} size="lg">
                            <ModalBody style={{padding:"30px"}}>
                                <div className="text-end">
                                    <i style={{cursor: 'pointer'}} onClick={handleHeadCostModal} className="mdi mdi-close noti-icon" />
                                </div>
                                <h5>Manage Head Cost Type</h5>
                                <Container>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup className="d-flex align-items-center text-nowrap">
                                                <Label for="headCostName" className="me-4 mb-0">Name</Label>
                                                <Input type="text"
                                                    placeholder="Type"
                                                    id="headCostName"
                                                    onChange={(e)=> setHeadCostType(e.target.value)}
                                                    value={headCostType}
                                                    >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup className="d-flex align-items-center text-nowrap">
                                                <Label for="headCostCode" className="me-4 mb-0">Code</Label>
                                                <Input type="text"
                                                    placeholder="Code"
                                                    id="headCostCode"
                                                    onChange={(e)=> setHeadCostCode(e.target.value)}
                                                    value={headCostCode}
                                                    >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={11}>
                                            <FormGroup className="d-flex align-items-center text-nowrap">
                                                <Label for="headCostDescription" className="me-4 mb-0">Description</Label>
                                                <Input type="text"
                                                    placeholder="Description"
                                                    id="headCostDescription"
                                                    onChange={(e)=> setHeadCostDescription(e.target.value)}
                                                    value={headCostDescription}
                                                    >
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={1}>
                                                <button className="btn btn-primary mb-3" style={{float: 'right'}} onClick={addHeadCostType}>Add</button>
                                        </Col>
                                        <Col>
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Code</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {headCostList?.map((headCostTypeRow) => (
                                                        <tr>
                                                            <td>{headCostTypeRow.ID}</td>
                                                            <td>{headCostTypeRow.Name}</td>
                                                            <td>{headCostTypeRow.Code}</td>
                                                            <td>{headCostTypeRow.Description}</td>
                                                            <td><button className="btn btn-danger" onClick={()=>handleDeleteModal(headCostTypeRow.ID)}>Delete</button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </ModalBody>
                        </Modal>

                    </Row>
                    {costError &&
                        <Alert color="danger">{costError}</Alert>
                    }
                    <Modal isOpen={confirmModal} toggle={()=> setConfirmModal(!confirmModal)} size="md">
                        <ModalBody style={{padding:"30px"}}>
                            
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <h5>Delete Head Cost</h5>
                                    </Col>
                                    <Col sm={12}>
                                        <p>Are you sure you want to delete this head cost?</p>
                                    </Col>
                                    <Col sm={12} className="text-end">
                                        <button className="btn btn-primary mx-2 px-4" onClick={()=> closeConfirmModal()}>No</button>
                                        <button className="btn btn-danger px-4" onClick={()=> handleRemoveHeadCost(tempId)}>Yes</button>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={transferHcModal} toggle={()=> setTransferHcModal(!transferHcModal)} size="md">
                        <ModalBody style={{padding:"30px"}}>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <h5>Select HeadCost</h5>
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCostFrom" className="me-4 mb-0" style={{width: '150px'}}>HeadCost From</Label>
                                            <Input type="select"
                                                placeholder="Head Cost From"
                                                id="headCostFrom"
                                                onChange={(e)=> setHeadCostFrom(e.target.value)}
                                                value={headCostFrom}
                                                >
                                                    <option>Select HeadCost</option>
                                                    {data?.headCost?.map((hcost) => (
                                                        <option value={hcost.HeadCostID}>{hcost.Name}</option>
                                                    ))}
                                            </Input>
                                        </FormGroup>
                                        {headCostFrom && <label>Amount : {data?.headCost?.find(x=> x.HeadCostID == headCostFrom)?.Amount}</label>}
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCostTo" className="me-4 mb-0" style={{width: '150px'}}>HeadCost To</Label>
                                            <Input type="select"
                                                placeholder="Head Cost To"
                                                id="headCostTo"
                                                onChange={(e)=> setHeadCostTo(e.target.value)}
                                                value={headCostTo}
                                                >
                                                    <option>Select HeadCost</option>
                                                    {data?.headCost?.filter(x=> x.HeadCostID != headCostFrom)?.map((hcost) => (
                                                        <option value={hcost.HeadCostID}>{hcost.Name}</option>
                                                    ))}
                                            </Input>
                                        </FormGroup>
                                        {headCostTo && <label>Amount : {data?.headCost?.find(x=> x.HeadCostID == headCostTo)?.Amount}</label>}

                                        {(headCostFrom && headCostTo) &&<FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="transferAmount" className="me-4 mb-0" style={{width: '150px'}}>Transfer Amount</Label>
                                            <Input type="text"
                                                placeholder="Transfer Amount"
                                                id="transferAmount"
                                                max={data?.headCost?.find(x=> x.HeadCostID == headCostFrom)?.Amount || 0}
                                                onChange={(e)=> handleChange(e.target.value)}
                                                value={transferAmount}
                                                />
                                        </FormGroup>}
                                    </Col>
                                    <Col sm={12} className="text-end">
                                        <button className="btn btn-danger mx-2 px-4" onClick={()=> setTransferHcModal(false)}>Cancel</button>
                                        <button className="btn btn-primary px-4" onClick={handleTransfer}>Transfer</button>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                    <Row>
                        
                        <Col sm={6} className="mb-2">
                            <h5>Head Cost Details</h5>
                        </Col>
                        <Col sm={6} className="mb-2">
                            <button className="btn btn-primary" style={{float:'right'}} onClick={()=> setTransferHcModal(true)}>Transfer HeadCost</button>
                        </Col>
                        <Col sm={12}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>Head Cost</th>
                                        <th>Type</th>
                                        <th>Head Cost Code</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        {userRole == 'Finance Officer' && (<th>Action</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.headCost?.map((hcost) => (
                                        <tr>
                                            <td>{hcost.Name}</td>
                                            <td>{hcost.Type}</td>
                                            <td>{hcost.Code}</td>
                                            <td>{hcost.Description}</td>
                                            <td>{hcost.Amount}</td>
                                            {userRole == 'Finance Officer' && (<td>
                                                <button className="btn btn-primary mx-2" onClick={() => updateHeadCost(hcost)}>Update</button>
                                                <button className="btn btn-danger" onClick={() => openConfirmModal(hcost.HeadCostID)}>Delete</button>
                                            </td>)}
                                        </tr>
                                    ))}
                                    {(data?.headCost && data?.headCost.length > 0) &&
                                        <>
                                            <tr>
                                                <td colSpan={4}><b>Utilized Budget</b></td>
                                                <td colSpan={2}>{totalHeadCost}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}><b>Remaining Budget</b></td>
                                                <td colSpan={2}>{TotalExpense - totalHeadCost}</td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </Table></Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h5>Activity Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>Log ID</th>
                                        <th>Type</th>
                                        <th>Field</th>
                                        <th>Old Value</th>
                                        <th>New Value</th>
                                        <th>Date</th>
                                        <th>Created By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ActivityLogs && ActivityLogs?.map((log) => (
                                        <tr>
                                            <td>{log.YearlyExpenseLogID}</td>
                                            <td>{log.type}</td>
                                            <td>{log.field}</td>
                                            <td>{log.oldValue}</td>
                                            <td>{log.newValue}</td>
                                            <td>{formatDateTime(log.changedAt)}</td>
                                            <td>{log.changedBy}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <h5>Approvals</h5>
                        <Col sm={6}>
                            <Table bordered responsive>
                                <tbody>
                                    <tr>
                                        <td>{data?.approvalUser?.Name}</td>
                                        <td>{data?.ApprovalStatus == "Rejected" ? "Re-Checked" : data?.ApprovalStatus}</td>
                                        {userRole == 'Permanent Secretary' && (data.ApprovalStatus == "Pending") && <td>
                                            <button className="btn btn-primary flex-fill" onClick={() => handleApproval("Approved")}>Approve</button>
                                            {/* <button className="btn btn-danger mx-2 flex-fill" onClick={() => handleApproval("Rejected")}>Reject</button> */}
                                            <button className="btn btn-danger mx-2 flex-fill" onClick={() => handleRejectClick()}>Re-Check</button>

                                        </td>}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {/* <Row>
                            <Col sm={12}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <button onClick={handleSubmit} className="btn btn-primary">Save</button>
                                </FormGroup>
                            </Col>
                        </Row> */}

                    <Row>
                        <Col sm={12}>
                            <h5>Approval Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        {/* <th>Log ID</th> */}
                                        <th>Approver Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ApprovalLogs && ApprovalLogs?.map((approve) => (
                                        <tr>
                                            {/* <td>{approve.ApprovalLogID}</td> */}
                                            <td>{approve.approver.Name}</td>
                                            <td>{approve.ApprovalStatus == 'Rejected' ? 'Re-Checked' : approve.ApprovalStatus}</td>
                                            <td>{formatDateTime(approve.ApprovalDate)}</td>
                                            <td>{approve.Comments}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UpdateYearlyExpense;
