import PropTypes from "prop-types";
import React, { useEffect, useCallback, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link, useLocation, useNavigate } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { get } from "axios";

const SidebarContent = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const path = location.pathname;

  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState(null);

  const [caseManagement, setCaseManagement] = useState(false);

  const getCANUUser = (role) => {
    if(
      role == 'CFO' ||
      role == 'Police Officer' ||
      role == 'Case Officer' ||
      role == 'Investigation Officer' ||
      role == 'SIO' ||
      role == 'Station Sergeant' ||
      role == 'SubDivisional Officer' ||
      role == 'Commander of Division' ||
      role == 'Division Headquarter' ||
      role == 'DPP' ||
      role == 'PCA' ||
      role == 'Legal Team'){
        setCaseManagement(true);
      }
  }

  useEffect(()=>{
    const u =getLoggedInUserRole() 
    getCANUUser(u);
    setUserRole(u);
    const uu = getLoggedInUser();
    console.log(uu)
    setUser(uu);
  },[]);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  // const activeMenu = useCallback(() => {
  //   const pathName = location.pathname;
  //   const fullPath = pathName;
  //   let matchingMenuItem = null;
  //   const ul = document.getElementById("side-menu");
  //   const items = ul.getElementsByTagName("a");
  //   removeActivation(items);

  //   for (let i = 0; i < items.length; ++i) {
  //     if (fullPath === items[i].pathname) {
  //       matchingMenuItem = items[i];
  //       break;
  //     }
  //   }
  //   if (matchingMenuItem) {
  //     activateParentDropdown(matchingMenuItem);
  //   }
  // }, [path, activateParentDropdown]);



  const activeMenu = useCallback(() => {
    const pathName = location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");

    // Only deactivate the previously active item without collapsing the entire menu
    const activeItems = ul.querySelectorAll(".active");
    for (let i = 0; i < activeItems.length; i++) {
      activeItems[i].classList.remove("active");
    }

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path, activateParentDropdown]);




  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

          {userRole == 'Help Desk Officer' &&
            <li>
              <Link className="has-arrow waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Building Security")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/visitor-management">{props.t("Visitor Management")}</Link>
                </li>
                <li>
                  <Link to="/visitor-ledger">{props.t("Visitor Ledger")} </Link>
                </li>
              </ul>
            </li> }

            {/* {userRole == 'Help Desk Officer' &&
            <li>
              <Link className="has-arrow waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Building Security")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/visitor-management">
                    <i className="ti-tablet"></i>
                    <span>{props.t("Visitor Management")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/visitor-ledger">
                    <i className="ti-user"></i>
                    <span>{props.t("Visitor Ledger")}</span>
                  </Link>
                </li>
              </ul>
            </li> } */}

            {/* {userRole != 'Help Desk Officer' &&
            <li>
              <Link className="has-arrow waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Voucher Manager")}</span>
              </Link>
            </li>} */}

            {(user?.email && !user?.email?.includes("Temp") && userRole != 'Help Desk Officer' && userRole != 'SMD' && userRole != 'CFO' && userRole != 'Department Officer' && !caseManagement) &&
            <li>
              <Link className="has-arrow waves-effect">
                <i className="ti-tablet"></i>
                <span>{props.t("Voucher Manager")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {userRole != 'Employee' && <li>
                  <Link to="/yearly-expense">
                    <i className="ti-money"></i>
                    <span>{props.t("Yearly Expense")}</span>
                  </Link>
                </li>}
                <li>
                  <Link to="/projects">
                    <i className="ti-tablet"></i>
                    <span>{props.t("Projects")}</span>
                  </Link>
                </li>
                {userRole != 'Employee' && <li>
                  <Link to="/employee">
                    <i className="ti-user"></i>
                    <span>{props.t("Employee")}</span>
                  </Link>
                </li>}
                {userRole != 'Employee' && <li>
                  <Link to="/vendor">
                    <i className="ti-id-badge"></i>
                    <span>{props.t("Vendor")}</span>
                  </Link>
                </li>}
                <li>
                  <Link to="/payment-voucher">
                    <i className="ti-receipt"></i>
                    <span>{props.t("Payment Voucher")}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {user?.email && user?.email?.includes("Temp") && userRole == 'HOD' &&
              <li>
                <Link to="/kpi-definition">
                  <i className="ti-list"></i>
                  <span>{props.t("KPI Definition")}</span>
                </Link>
              </li>
            }

            {user?.email && user?.email?.includes("Temp") && userRole == 'SMD' &&
              <li>
                <Link to="/kpi-report">
                  <i className="ti-file"></i>
                  <span>{props.t("Report")}</span>
                </Link>
              </li>
            }

            {(user?.email && !user.email.includes("Temp") && (userRole == 'SMD' || userRole == 'HOD' || userRole == 'Department Officer' || userRole == 'Permanent Secretary' || userRole == 'Finance Officer' || userRole == 'Preparation Clerk')) &&
              <li>
                <Link className="has-arrow waves-effect">
                  <i className="ti-tablet"></i>
                  <span>{props.t("Monitoring & Evaluation")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/kpi-definition">
                      <i className="ti-list"></i>
                      <span>{props.t("KPI Definition")}</span>
                    </Link>
                  </li>
                  {userRole != 'Department Officer' && <li>
                    <Link to="/kpi-report">
                      <i className="ti-file"></i>
                      <span>{props.t("Report")}</span>
                    </Link>
                  </li>}
                </ul>
              </li>
            }

            {caseManagement &&
              <li>
                <Link className="has-arrow waves-effect">
                  <i className="ti-tablet"></i>
                  <span>{props.t("Case Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/case-management">
                      <i className="ti-money"></i>
                      <span>{props.t("Case Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/create-case">
                      <i className="ti-money"></i>
                      <span>{props.t("Create Case")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {/* {userRole != 'Help Desk Officer' &&
            <li>
            <Link to="/employee">
                <i className="ti-user"></i>
                <span>{props.t("Employee")}</span>
              </Link>
            </li>}

            {userRole != 'Help Desk Officer' &&
            <li>
            <Link to="/vendor">
                <i className="ti-id-badge"></i>
                <span>{props.t("Vendor")}</span>
              </Link>
            </li>}
            {userRole != 'Help Desk Officer' &&
            <li>
            <Link to="/yearly-expense">
                <i className="ti-money"></i>
                <span>{props.t("Yearly Expense")}</span>
              </Link>
            </li>} */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
